const LAYOUT = {
  // Primary Color
  APP_BAR_HEIGHT: 64,
  DRAWER_WIDTH: 280,
  MICRO_WIDTH: 370,
  MICRO_HEADER_HEIGHT: 60,
  MICRO_SEARCHBAR_HEIGHT: 35,
  MICRO_LIST_BOX_HEIGHT: 120,
  MICRO_HEADER_SPACING: 30,
  CUST_MICR_LIST_BOX_HEIGHT: 100,

};

export default LAYOUT;
