import {
  Backup, Help,
} from '@mui/icons-material';
import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import COLORS from 'constants/COLORS';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function FileInput({ formik }) {
  const { enqueueSnackbar } = useSnackbar();

  const [isResetFile, setIsResetFile] = useState(false);

  const { setFieldValue, getFieldProps } = formik;

  const isFileAdded = getFieldProps('file').value != null;

  return (
    <>
      <Grid
        container
        columnSpacing={1}
        rowSpacing={2}
      >
        <Grid item xs={6} sm={6}>
          <Button
            variant={isFileAdded ? 'outlined' : 'contained'}
            component="label"
            size="small"
            color={isFileAdded ? 'primary' : 'secondary'}
            startIcon={<Backup />}
            sx={{ mr: 2, fontSize: 11 }}
          >
            {isFileAdded
              ? 'Change file'
              : 'Select a new file'}
            <input
              type="file"
              hidden
              key={isResetFile}
              onChange={(e) => {
                const uploadedFile = e.target.files[0];

                const validationReset = () => {
                  setFieldValue('file', null);
                  setIsResetFile(!isResetFile);
                };

                if (!uploadedFile) {
                  enqueueSnackbar('Please select your file', { variant: 'error' });
                  validationReset();
                } else if (!uploadedFile.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
                  enqueueSnackbar('Please select a valid format (pdf, jpg, jpeg, png)', { variant: 'error' });
                  validationReset();
                } else if (uploadedFile.size <= 0) {
                  enqueueSnackbar('File not found, file might be corrupted or deleted', { variant: 'error' });
                  validationReset();
                } else if (uploadedFile.size > 10000000) {
                  enqueueSnackbar('File size must be below 10 MB', { variant: 'error' });
                  validationReset();
                } else {
                  setFieldValue('file', uploadedFile, true);
                }
              }}
            />
          </Button>
        </Grid>

      </Grid>
      <Box sx={{ height: 20 }} />
      <Typography variant="body2" sx={{ color: COLORS.labelSecondary }}>
        <Help fontSize="small" sx={{ mr: 1, mb: -0.75 }} />
        {' '}
        File size must be
        {' '}
        <b>below 10MB</b>
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: COLORS.labelSecondary, mt: 1 }}
      >
        <Help fontSize="small" sx={{ mr: 1, mb: -0.75 }} />
        {' '}
        File format must be in
        {' '}
        <b>jpg, png or pdf only</b>
      </Typography>
    </>
  );
}
