import { enqueueSnackbar } from 'notistack';

// STORES
import { SecureAdminAxios, SecureInvoiceAxios } from 'util/API';
import { getErrMessageUtil } from 'util/util';

export const getOneInvoiceByIdAPI = async (invoiceId) => {
  const url = `/invoice/one/${invoiceId}`;

  try {
    const res = await SecureAdminAxios.get(url);

    return [true, res.data.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const createBulkInvoicessByZipAPI = async (body, selectedBizId) => {
  const url = `/invoice/create/many/${selectedBizId}`;

  try {
    const res = await SecureInvoiceAxios.post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return [true, res.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, err.response.data];
  }
};
