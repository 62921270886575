import { enqueueSnackbar } from 'notistack';

// STORES
import { SecureAdminAxios } from 'util/API';
import { getErrMessageUtil } from 'util/util';

export const getLodInvoicesAPI = async (payeeBusinessId, lodType = 'request') => {
  const url = `/lod/invoices/${payeeBusinessId}?lodType=${lodType}`;

  try {
    const res = await SecureAdminAxios.get(url);

    return [true, res.data.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const postRequestLodAPI = async (invoiceIds) => {
  const url = '/lod/request';

  try {
    const res = await SecureAdminAxios.post(url, {
      invoiceIds,
    });

    return [true, res.data.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, err.response.data];
  }
};

export const getLodOneInvoicesAPI = async (payeeBusinessId) => {
  const url = `/lod/lod-one-invoice/${payeeBusinessId}`;

  try {
    const res = await SecureAdminAxios.get(url);

    return [true, res.data.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const putPauseResumeOneLodAPI = async (lodId) => {
  const url = `/lod/pause-resume-one/${lodId}`;

  try {
    const res = await SecureAdminAxios.put(url);

    return [true, res.data.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const putUpdateOneInvoiceEscalationAPI = async (invoiceId, escalation) => {
  const url = `/lod/update-one-invoice-escalation/${invoiceId}`;

  try {
    const res = await SecureAdminAxios.put(url, { escalation });

    return [true, res.data.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const postPauseResumeManyLodAPI = async (lodIds, type) => {
  const url = '/lod/pause-resume-many';

  try {
    const res = await SecureAdminAxios.post(url, { lodIds, type });

    return [true, res.data.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const postUpdateManyInvoiceEscalationAPI = async (invoiceIds, escalation) => {
  const url = '/lod/update-many-invoice-escalation';

  try {
    const res = await SecureAdminAxios.post(url, { invoiceIds, escalation });

    return [true, res.data.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};
