import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material';

import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Close } from '@mui/icons-material';
import { createBulkInvoicessByZipAPI } from 'services/invoice-api-services';

export default function CreateBulkInvoiceModal({ selectedBizId, handleClose }) {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inputKey, setInputKey] = useState(Date.now());

  const { enqueueSnackbar } = useSnackbar();

  const handleCsvFileUpload = async () => {
    if (!file) {
      enqueueSnackbar('Please choose a file', { variant: 'warning' });
      return;
    }

    // eslint-disable-next-line no-alert
    if (!window.confirm('Confirm Create bulk DRAFT INVOICE?')) {
      return;
    }

    setError(null);
    setIsLoading(true);
    const formData = new FormData();
    formData.append('invoices_zip', file);
    const [status, result] = await createBulkInvoicessByZipAPI(formData, selectedBizId);
    setIsLoading(false);

    if (!status) {
      setFile(null);
      setInputKey(Date.now());
      try {
        const prettifiedError = JSON.stringify(result?.errors, null, 2);
        setError(prettifiedError);
      } catch (e) {
        console.error(e);
        // Invalid JSON, do nothing
      }

      return;
    }

    const successCount = result?.data?.length;
    enqueueSnackbar(`🎉 Successfully created ${successCount} draft invoice!`, {
      variant: 'success',
    });
    handleClose();
  };

  const onClose = () => {
    if (isLoading) return;

    handleClose();
  };

  return (

    <BootstrapDialog
      open={selectedBizId != null && selectedBizId !== ''}
      onClose={() => {}}
      maxWidth="xl"
      fullWidth
      disableEscapeKeyDown={isLoading}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        Create Bulk Draft Invoices By CSV
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid2 container spacing={4}>
          <Grid2 xs={5}>
            <DialogContentText
              sx={{
                py: 2,
              }}
            >
              {`Selected Business ID: ${selectedBizId}`}
            </DialogContentText>
            <TextField
              key={inputKey}
              id="contained-button-file"
              multiple
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              fullWidth
              sx={{
                width: '100%',
                py: 2,
                '& .MuiInputBase-root': {
                  borderRadius: 1,
                  border: file != null ? '1px solid #ced4da' : '1px solid red',
                  padding: '10px 12px',
                  fontSize: 14,
                  width: '100%',
                },
                '& .MuiInputBase-root:hover': {
                  borderColor: '#80bdff',
                  boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
                },
              }}
              helperText={'Kindly bear in mind that the processing time may increase with the number of invoices uploaded. We appreciate your patience during this process.'}
            />
          </Grid2>
          {error != null
          && (
          <Grid2 xs={7}>
            <Typography variant="body2" fontWeight="bold" color="red">CSV Validation Error</Typography>
            <pre style={{ fontSize: 11, color: 'red' }}>{error}</pre>
          </Grid2>
          )}
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            width: '100%',
          }}
        >
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => handleCsvFileUpload()}
            loading={isLoading}
            autoFocus
            disabled={file == null}
          >
            Upload
          </LoadingButton>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
