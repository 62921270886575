import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';

import {
  Box, IconButton, InputAdornment, TextField,
} from '@mui/material';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useStoreAuth } from 'store/auth-store';
import { getErrMessageUtil } from '../../util/util';
import { getAPI } from '../../util/API';

export default function SignInForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const setIsAuthStore = useStoreAuth((s) => s.setIsAuthStore);
  const setUserSessionKeyStore = useStoreAuth((s) => s.setUserSessionKeyStore);

  const SignInSchema = Yup.object().shape({
    email: Yup
      .string()
      .email('Please enter a valid email address')
      .required('Please enter a valid email address'),
    password: Yup
      .string()
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SignInSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const API = getAPI('admin');
      const url = '/auth/login';
      API.post(url, values)
        .then((res) => {
          const { data } = res.data;
          setIsAuthStore(true);
          setUserSessionKeyStore(data.token);
          localStorage.setItem('session_token', data.token);

          enqueueSnackbar('Login successful', { variant: 'success' });
          navigate('/admin/user');
        })
        .catch((err) => {
          const errMessage = getErrMessageUtil(err);
          enqueueSnackbar(errMessage, { variant: 'error' });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const {
    errors, touched, handleSubmit, getFieldProps, isSubmitting,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

        <TextField
          fullWidth
          type="email"
          label="Email address"
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
          {...getFieldProps('email')}
        />
        <Box sx={{ mb: 3 }} />

        <TextField
          fullWidth
          type={showPassword ? 'text' : 'password'}
          label="Password"
          {...getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => { setShowPassword(!showPassword); }} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
        />
        {' '}

        <Box
          sx={{
            my: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ p: 2 }}
          loading={isSubmitting}
        >
          Sign in

        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
