import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Container } from '@mui/material';
import { last } from 'lodash';

const MENU = [
  {
    title: 'Home',
    to: '/home',
  },
  {
    title: 'Fees',
    type: 'text',
    to: '/fee',
  },
  {
    title: 'Payee (Creditor)',
    type: 'subtab',
    to: '/fee-payee',
  },
  {
    title: 'Payer (Debtor)',
    type: 'subtab',
    to: '/fee-payer',
  },
  {
    title: 'LoD',
    type: 'text',
    to: '/lod',
  },
  {
    title: 'Request',
    to: '/lod-request',
    type: 'subtab',
  },
  {
    title: 'LoD 1',
    to: '/lod-one',
    type: 'subtab',
  },
  {
    title: 'LoD 2',
    to: '/lod-two',
    type: 'subtab',
  },

];

export default function UserBizLayout() {
  const { userId, bizId } = useParams();
  const navigate = useNavigate();

  const previousPath = `/admin/user/${userId}/${bizId}`;

  const path = last(window.location.pathname.split(previousPath));

  return (
    <Box
      sx={{
        flexGrow: 1, display: 'flex', px: 4,
      }}
    >
      <Tabs
        orientation="vertical"
        value={path}
        onChange={(e, newValue) => {
          navigate(`${previousPath}${newValue}`);
        }}
        sx={{
          borderLeft: 1,
          borderColor: 'divider',
          py: 3,
          width: 200,
          '.MuiTabs-indicator': {
            left: 0,
            right: 'inherit',
          },
        }}
      >
        {MENU.map((v) => {
          const selected = path === v?.to;

          return (
            <Tab
              key={v.to}
              label={`${v.title}`}
              value={v.to}
              disabled={v?.type === 'text'}
              sx={v?.type === 'subtab' ? { ...styles.subtabStyle, fontWeight: selected ? 'bold' : 'normal' } : { ...styles.tabStyle, fontWeight: selected ? 'bold' : 'normal' }}
            />
          );
        })}
      </Tabs>
      <Container maxWidth="xl">
        <Outlet />
      </Container>
    </Box>
  );
}

const styles = {
  tabStyle: {
    minHeight: 0,
    my: 0.5,
    height: 30,
    alignItems: 'flex-start',
    p: 0,
    pl: 2,
    textTransform: 'none',

  },

  subtabStyle: {
    minHeight: 0,
    my: 0.25,
    height: 30,
    alignItems: 'flex-start',
    p: 0,
    pl: 3,
    textTransform: 'none',
    fontSize: 13,
  },
};
