import FullScreenLoader from 'components/shared/FullScreenLoader/FullScreenLoader';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOneInvoiceByIdAPI } from 'services/invoice-api-services';
import { useStoreDs } from 'store/debtRestructure-store';
import { DebtRestructureOneInvoiceActive, DebtRestructureOneInvoiceDraft } from '../../../components/DebtRestructure/DebtRestructureOneInvoice';

export default function DebtRestructureOneInvoiceScreen() {
  const { invoiceId } = useParams();

  const [invoice, setInvoice] = useState(null);
  const { refreshDsStore } = useStoreDs();
  const [isLoading, setIsLoading] = useState(false);

  const getOneInvoice = async () => {
    setIsLoading(true);
    const [status, data] = await getOneInvoiceByIdAPI(invoiceId);
    setIsLoading(false);

    if (!status) return;

    setInvoice(data);
  };

  useEffect(() => {
    if (!invoiceId) return;

    getOneInvoice();
  }, [invoiceId, refreshDsStore]);

  const getDsState = () => {
    if (invoice?.debt_restructuring?.status === 'active') {
      return <DebtRestructureOneInvoiceActive invoice={invoice} />;
    }

    return <DebtRestructureOneInvoiceDraft invoice={invoice} />;
  };

  return !isLoading && invoice != null ? (
    <>
      {getDsState()}
    </>
  ) : <FullScreenLoader />;
}
