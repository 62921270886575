import React from 'react';
import COLORS from 'constants/COLORS';
import { Grid, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

export default function ContentGrid({
  label, value, valueColor = COLORS.labelPrimary, tooltip = '',
}) {
  return (
    <Tooltip title={tooltip} arrow>
      <Grid item xs={6} sm={4} md={4} lg={3}>
        <Typography variant="body2" sx={{ color: grey[600] }}>{label}</Typography>
        <Typography sx={{ fontWeight: 600, color: valueColor, wordBreak: 'break-word' }}>{value}</Typography>
      </Grid>
    </Tooltip>
  );
}
