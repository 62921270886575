import {
  Box,

  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  TextField,
} from '@mui/material';
import React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { UpdateOneTransactionFeeAPI } from 'services/pricing-api-services';
import { enqueueSnackbar } from 'notistack';
import { round } from 'lodash';

const TYPE = 'lod';

export default function LodModal({
  bizId,
  handleClose,
  updateFee,
  open,
  getAllFees,
  userType,
}) {
  const fees = updateFee?.amount?.[0];

  const formik = useFormik({
    initialValues: {
      fixed_amount: fees?.fixed_amount || 0,
      percentage_amount: fees?.percentage_amount || 0,
    },
    validationSchema: Yup.object().shape({
      fixed_amount: Yup.number()
        .min(0, 'Transaction Fees Amount must be greater than or equal to 0')
        .required('Transaction Fees Amount is required'),
      percentage_amount: Yup.number()
        .min(0, 'Transaction Fees Amount must be greater than or equal to 0')
        .max(100, 'Transaction Fees Amount must be less than or equal to 100')
        .required('Transaction Fees Amount is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const requestBody = [{
        payee_business_id: bizId,
        transaction_fees: [
          {
            identifier: updateFee.identifier,
            amount: [
              {
                fixed_amount: round(values.fixed_amount, 2),
                percentage_amount: round(values.percentage_amount, 2),
              },
            ],
          },
        ],
        type: TYPE,
        user: userType,
        lod_type: updateFee.lod_type,
      }];

      const [status] = await UpdateOneTransactionFeeAPI(requestBody);
      setSubmitting(false);

      if (status) {
        getAllFees();
        enqueueSnackbar(`Updated ${TYPE} ${updateFee?.lod_type} ${updateFee?.name} Fee Successfully`, {
          variant: 'success',
        });
        handleClose();
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    isSubmitting,
  } = formik;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {`Update ${TYPE} Fee`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Payee Business ID:
          {' '}
          {bizId}
        </DialogContentText>

        <DialogContentText>
          Lod Type:
          {' '}
          {updateFee?.lod_type}
        </DialogContentText>
        <DialogContentText>
          Fee Name:
          {' '}
          {updateFee?.name}
        </DialogContentText>
        <DialogContentText>
          Fee Identifier:
          {' '}
          {updateFee?.identifier}
        </DialogContentText>
      </DialogContent>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box
        sx={{
          m: 3,
        }}
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

            <FormControl fullWidth sx={{ mt: 2, mb: 4 }}>
              <TextField
                fullWidth
                label="Transaction Fees Amount (MYR)"
                type="number"
                {...getFieldProps('fixed_amount')}
                error={Boolean(touched.fixed_amount && errors.fixed_amount)}
                helperText={touched.fixed_amount && errors.fixed_amount}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2, mb: 4 }}>
              <TextField
                fullWidth
                label="Transaction Fees Percentange (%)"
                type="number"
                {...getFieldProps('percentage_amount')}
                error={Boolean(touched.percentage_amount && errors.percentage_amount)}
                helperText={touched.percentage_amount && errors.percentage_amount}
              />
            </FormControl>

            <DialogActions>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  width: '100%',
                }}
              >
                <LoadingButton
                  onClick={handleClose}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  autoFocus
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  Update
                </LoadingButton>
              </Box>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Box>
    </Dialog>
  );
}
