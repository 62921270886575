import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { UpdateOneTransactionFeeAPI } from 'services/pricing-api-services';
import { round } from 'lodash';

export default function OverDueManyModal({
  bizId,
  handleClose,
  updateAllTier,
  open,
  getAllFees,
  userType,
  tiers,
}) {
  const formik = useFormik({
    initialValues: {
      fixed_amount: 0,
      percentage_amount: 0,
      payment_mode: '',
    },
    validationSchema: Yup.object().shape({
      fixed_amount: Yup.number()
        .min(0, 'Transaction Fees Amount must be greater than or equal to 0')
        .required('Transaction Fees Amount is required'),
      percentage_amount: Yup.number()
        .min(0, 'Transaction Fees Amount must be greater than or equal to 0')
        .max(100, 'Transaction Fees Amount must be less than or equal to 100')
        .required('Transaction Fees Amount is required'),
      payment_mode: Yup.string()
        .oneOf(['fpx', 'ewallet', 'card'], 'Invalid Payment Mode')
        .required('Payment Mode is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const requestBody = tiers.map((t) => ({
        payee_business_id: bizId,
        payment_method: values.payment_mode,
        type: 'overdue',
        user: userType,
        transaction_fees: [
          {
            identifier: updateAllTier.identifier,
            amount: [
              {
                fixed_amount: round(values.fixed_amount, 2),
                percentage_amount: round(values.percentage_amount, 2),
              },
            ],
          },
        ],
        tier: t,
      }));

      const [status] = await UpdateOneTransactionFeeAPI(requestBody);
      setSubmitting(false);

      if (status) {
        getAllFees();
        handleClose();
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    isSubmitting,
  } = formik;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Update All Overdue Fee
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Fee Identifier:
          {' '}
          {updateAllTier?.identifier}
        </DialogContentText>
        <DialogContentText>
          Fee Name:
          {' '}
          {updateAllTier?.name}
        </DialogContentText>
      </DialogContent>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box
        sx={{
          m: 3,
        }}
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              error={Boolean(
                touched.payment_mode && errors.payment_mode,
              )}
              sx={{ mt: 2, mb: 4 }}
            >
              <InputLabel id="payment_mode">
                Payment Mode
              </InputLabel>
              <Select
                labelId="payment_mode"
                id="payment_mode"
                {...getFieldProps('payment_mode')}
                label="Payment Mode"
              >
                <MenuItem disabled value="">
                  Select Payment Mode Type
                </MenuItem>
                <MenuItem value="fpx">FPX</MenuItem>
                <MenuItem value="ewallet">E-Wallet</MenuItem>
                <MenuItem value="card">Card</MenuItem>
              </Select>
              <FormHelperText>
                {touched.payment_mode && errors.payment_mode
                  ? errors.payment_mode
                  : null}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2, mb: 4 }}>
              <TextField
                fullWidth
                label="Transaction Fees Amount (MYR)"
                type="number"
                {...getFieldProps('fixed_amount')}
                error={Boolean(touched.fixed_amount && errors.fixed_amount)}
                helperText={touched.fixed_amount && errors.fixed_amount}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2, mb: 4 }}>
              <TextField
                fullWidth
                label="Transaction Fees Percentange (%)"
                type="number"
                {...getFieldProps('percentage_amount')}
                error={Boolean(touched.percentage_amount && errors.percentage_amount)}
                helperText={touched.percentage_amount && errors.percentage_amount}
              />
            </FormControl>

            <DialogActions>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  width: '100%',
                }}
              >
                <LoadingButton
                  onClick={handleClose}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  autoFocus
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  Update
                </LoadingButton>
              </Box>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Box>
    </Dialog>
  );
}
