import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
// COMPONENTS
import SignInForm from '../../components/Auth/SignInForm';

export default function AuthScreen() {
  return (
    <Box sx={{ width: '100vw', height: '100vh' }}>
      <AuthContainer container>
        <AuthCard item xs={12} lg={6}>
          <Box sx={{ maxWidth: 500, margin: 'auto', py: 4 }}>
            <CompanyLogo
              alt="company"
              src="/images/ara-app-logo.png"
              style={{ height: '35px', width: '140px' }}
            />
            <Box sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Sign in to Ara Admin
              </Typography>
              <Typography color="textSecondary" sx={{ fontWeight: 500 }}>
                Enter your details below
              </Typography>
            </Box>
            <SignInForm />
          </Box>
        </AuthCard>
        <Grid item xs={12} lg={6}>
          <RightImage>
            <Box
              sx={{
                width: '60%',
                textAlign: 'center',
                py: 4,
              }}
            >
              <Typography variant="h5" sx={{ color: '#fff', fontWeight: 700 }}>
                Made With Love by the Ara Team
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: '#fff',
                  fontWeight: 500,
                  mt: 2,
                  mb: 4,
                }}
              >
                For your convenience, management, seamless experience and more!
              </Typography>
              <img
                alt="company"
                src="/images/sign-in-illustration.svg"
                style={{ width: '100%' }}
              />
            </Box>
          </RightImage>
        </Grid>
      </AuthContainer>
    </Box>
  );
}

const AuthContainer = styled(Grid)(() => ({
  height: '100vh',
  width: '100vw',
}));

const AuthCard = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
  alignSelf: 'center',
}));

const CompanyLogo = styled('img')(() => ({
  marginBottom: 30,
}));

const RightImage = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  background:
    'radial-gradient(50% 50% at 50% 50%, rgb(18, 38, 71) 0%, rgb(9, 14, 35) 100%)',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}));
