import { create } from 'zustand';

const initialAuthState = {
  userSessionKeyStore: '',
  isAuthStore: false,
};

export const useStoreAuth = create((set) => ({
  ...initialAuthState,
  resetAuthStorageAndStore: () => {
    localStorage.removeItem('session_token');
    set({
      ...initialAuthState,
    });
  },

  setIsAuthStore: (bool) => set({ isAuthStore: bool }),
  setUserSessionKeyStore: (str) => set({ userSessionKeyStore: str }),
}));
