import { enqueueSnackbar } from 'notistack';

// STORES
import { SecureFileAxios, SecureInvoiceAxios } from 'util/API';
import { getErrMessageUtil } from 'util/util';

export const createOneDebtRestructuringAPI = async (body) => {
  const url = '/debtrestructuring/admin/create/one';

  try {
    const res = await SecureInvoiceAxios.post(url, body);

    return [true, res.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, err.response.data];
  }
};

export const updateOneDebtRestructuringAPI = async (dsId, body = {}) => {
  const url = `/debtrestructuring/admin/update/one/${dsId}`;

  try {
    const res = await SecureInvoiceAxios.put(url, body);

    return [true, res.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, err.response.data];
  }
};

export const deleteOneDebtRestructuringAPI = async (dsId) => {
  const url = `/debtrestructuring/admin/delete/one/${dsId}`;

  try {
    const res = await SecureInvoiceAxios.delete(url);

    return [true, res.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, err.response.data];
  }
};

export const activateOneDebtRestructuringAPI = async (dsId) => {
  const url = `/debtrestructuring/admin/change-status/${dsId}`;

  try {
    const res = await SecureInvoiceAxios.put(url);

    return [true, res.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, err.response.data];
  }
};

export const getOneDebtRePaymentLinkAPI = async (dsId) => {
  const url = `/debtrestructuring/admin/get/payment_links/${dsId}`;

  try {
    const res = await SecureInvoiceAxios.get(url);

    return [true, res.data.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, err.response.data];
  }
};

export const markAsPaidOneDebtRestructuringAPI = async (dsId, businessId, markAsPaidData, file = null) => {
  if (!dsId || !businessId) return [null];

  const fileURL = '/file/receipt/upload/one?allow_admin=true';
  const dsURL = `/debtrestructuring/admin/mark-as-paid/${dsId}`;

  try {
    let body = {
      paid_at: new Date(markAsPaidData.paid_at),
      payment_mode: markAsPaidData.paid_with,
      amount_paid: markAsPaidData.amount_paid,
    };

    if (file) {
      const fileData = new FormData();

      fileData.append('file', file);
      fileData.append('businessId', businessId);
      const fileUploadRes = await SecureFileAxios.post(fileURL, fileData, { headers: { 'Content-Type': 'multipart/form-data' } });
      const receiptUrl = fileUploadRes.data.data.url;

      body = {
        ...body,
        receipt_url: receiptUrl,
      };
    }

    const markPaidRes = await SecureInvoiceAxios.put(dsURL, body);

    return [true, markPaidRes.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};
