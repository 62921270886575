import * as React from 'react';
import {
  Typography, Box, FormControlLabel, Radio, RadioGroup, TextField, Dialog, Button, InputAdornment, Divider,
} from '@mui/material';
import * as Yup from 'yup';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import FileInput from './FileInput';
import { blockInvalidCharNumberInput } from 'util/util';
import { markAsPaidOneDebtRestructuringAPI } from 'services/debtrestructuring-api-services';
import { enqueueSnackbar } from 'notistack';
import { useStoreDs } from 'store/debtRestructure-store';

const PAID_WITH_OPTIONS = ['Bank Transfer', 'GIRO', 'Cheque', 'Cash'];

export default function ActiveMarkPaidModal({
  debtRestructuring, open, handleClose, payeeBusinessId,
}) {
  const { TriggerRefreshDsStore } = useStoreDs();

  const maxProcessableValue = debtRestructuring?.amount?.amount_due || 0;

  const formik = useFormik({
    initialValues: {
      paid_with: '',
      paid_at: new Date(),
      amount_paid: maxProcessableValue,
      file: null,
    },
    // enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      amount_paid: Yup
        .number()
        .required('Amount Paid is required')
        .min(1, 'Amount Paid should be at least MYR 1.00')
        .max(maxProcessableValue, `Amount Paid exceeded invoice amount due of: MYR ${maxProcessableValue}`)
        .test('min_processable_value', 'Kindly note that the outstanding invoice debt balance should not decrease below RM15.00', (value) => {
          // Calculate the difference between maxProcessableValue and amount_paid
          const difference = maxProcessableValue - value;

          // If the difference is in the range [0.01, 15.00]
          if (difference > 0 && difference < 15.00) {
            return false; // Test fails
          }
          return true; // Test passes
        }),
      paid_with: Yup
        .string()
        .required('Paid with is required'),
      paid_at: Yup
        .date('Invalid Date')
        .typeError('Invalid Date')
        .required('Paid on date is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const [markAsPaidStatus] = await markAsPaidOneDebtRestructuringAPI(debtRestructuring._id, payeeBusinessId, values, values.file || null);
      setSubmitting(false);

      if (!markAsPaidStatus) return;

      enqueueSnackbar('Debt Restructuring has been successfully updated.', { variant: 'success' });
      handleClose();
      TriggerRefreshDsStore();
    },
  });

  const {
    getFieldProps, setFieldValue, isValid, touched, errors, values, handleChange, isSubmitting, handleSubmit,
  } = formik;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        if (isSubmitting) return;

        handleClose();
      }}
    >
      <Box sx={{ py: 3, px: 3.5 }}>
        <Typography variant="h6" sx={{ mb: 0.5 }}>
          Debt Restructure Mark as Paid
        </Typography>

        <Box sx={{ height: 20 }} />
        <Divider />
        <Box sx={{ height: 20 }} />
        <Typography fontWeight={700}>Paid with</Typography>
        <RadioGroup
          name="paid_with"
          row
          value={values.paid_with}
          onChange={handleChange}
          size="small"
        >
          {PAID_WITH_OPTIONS.map((opt) => <FormControlLabel value={opt} control={<Radio />} label={opt} key={opt} />)}
        </RadioGroup>
        {Boolean(touched.paid_with && errors.paid_with) && (
          <Typography sx={{ color: 'red' }}>{errors.paid_with}</Typography>
        )}
        <Box sx={{ height: 40 }} />
        <TextField
          fullWidth
          type="number"
          onWheel={(e) => e.target.blur()}
          onKeyDown={blockInvalidCharNumberInput}
          label={'Amount paid'}
          {...getFieldProps('amount_paid')}
          error={Boolean(touched.amount_paid && errors.amount_paid)}
          helperText={touched.amount_paid && errors.amount_paid}
          InputProps={{
            startAdornment: <InputAdornment position="start">MYR</InputAdornment>,
            endAdornment: <Button size="small" variant="outlined" disableElevation onClick={() => { setFieldValue('amount_paid', maxProcessableValue); }}>Max</Button>,
          }}
        />
        <Box sx={{ height: 20 }} />
        <MobileDatePicker
          label="Paid on"
          value={getFieldProps('paid_at').value}
          format="dd-MMM-yyyy"
          onChange={(val) => {
            setFieldValue('paid_at', val);
          }}
          fullWidth
          slotProps={{
            textField: {
              variant: 'outlined',
              fullWidth: true,
              error: Boolean(touched.paid_at && errors.paid_at),
              helperText: errors.paid_at,
            },
          }}
          maxDate={new Date()}
        />
        <Box sx={{ height: 40 }} />
        <Typography>Upload proof of payment (Optional)</Typography>
        <Box sx={{ height: 15 }} />
        <FileInput formik={formik} />
        <Box sx={{ height: 30 }} />
        <Divider />
        <Box sx={{ height: 30 }} />
        <LoadingButton variant="contained" fullWidth disabled={!isValid} loading={isSubmitting} onClick={handleSubmit}>
          {getFieldProps('amount_paid').value === maxProcessableValue ? 'Update as fully paid' : 'Update as partially paid'}
        </LoadingButton>
        <Box sx={{ height: 15 }} />
        <LoadingButton fullWidth onClick={handleClose}>Cancel</LoadingButton>
      </Box>

    </Dialog>
  );
}
