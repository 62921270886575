/* eslint-disable no-param-reassign */
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useStoreAuth } from 'store/auth-store';
import CONFIG from '../constants/CONFIG';

export const SecureAdminAxios = axios.create({
  baseURL: CONFIG.API[process.env.REACT_APP_ENV].V1_ADMIN_API_URL,
  timeout: 20000000,
  headers: {
    'Content-Type': 'application/json',
  },
});

SecureAdminAxios.interceptors.request.use(async (config) => {
  try {
    const { userSessionKeyStore } = useStoreAuth.getState();

    // 1) If user is not login, return config
    if (!userSessionKeyStore) {
      enqueueSnackbar('You are not Login / Authorized for API call', { variant: 'error' });

      return config;
    }

    // 2) If is Login, set header token
    config.headers.Authorization = `Bearer ${userSessionKeyStore}`;

    return config;
  } catch (e) {
    return config;
  }
});

export const SecureInvoiceAxios = axios.create({
  baseURL: CONFIG.API[process.env.REACT_APP_ENV].V1_INVOICE_API_URL,
  timeout: 20000000,
  headers: {
    'Content-Type': 'application/json',
  },
});

SecureInvoiceAxios.interceptors.request.use(async (config) => {
  try {
    const { userSessionKeyStore } = useStoreAuth.getState();

    // 1) If user is not login, return config
    if (!userSessionKeyStore) {
      enqueueSnackbar('You are not Login / Authorized for API call', { variant: 'error' });

      return config;
    }

    // 2) If is Login, set header token
    config.headers.Authorization = `Bearer ${userSessionKeyStore}`;

    return config;
  } catch (e) {
    return config;
  }
});

export const SecureFileAxios = axios.create({
  baseURL: CONFIG.API[process.env.REACT_APP_ENV].V1_FILE_API_URL,
  timeout: 20000000,
  headers: {
    'Content-Type': 'application/json',
  },
});

SecureFileAxios.interceptors.request.use(async (config) => {
  try {
    const { userSessionKeyStore } = useStoreAuth.getState();

    // 1) If user is not login, return config
    if (!userSessionKeyStore) {
      enqueueSnackbar('You are not Login / Authorized for API call', { variant: 'error' });

      return config;
    }

    // 2) If is Login, set header token
    config.headers.Authorization = `Bearer ${userSessionKeyStore}`;

    return config;
  } catch (e) {
    return config;
  }
});

export const getAPI = (serviceType = 'auth', timeout = 20000) => {
  const URL = CONFIG.API[process.env.REACT_APP_ENV][
    `V1_${serviceType.toUpperCase()}_API_URL`
  ];
  const api = axios.create({
    baseURL: URL,
    timeout,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  api.CancelToken = axios.CancelToken;
  api.isCancel = axios.isCancel;

  return api;
};
