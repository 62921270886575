import React, { useEffect, useState } from 'react';
import { useStoreAuth } from 'store/auth-store';
import { getAPI } from 'util/API';
import {
  Box, Typography,
} from '@mui/material';
import { getErrMessageUtil } from 'util/util';
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { UserBizFeeLodTable, UserBizNotDueTable, UserBizOverDueTable } from 'components/User/UserBiz/UserBizFee';

export default function UserBizFeePayeeScreen() {
  const { bizId } = useParams();
  const { userSessionKeyStore } = useStoreAuth();

  const [isLoading, setIsLoading] = useState(true);

  const [notDue, setNotDue] = useState([]);
  const [overDue, setOverDue] = useState([]);
  const [lod, setLod] = useState([]);

  const getAllFees = async () => {
    const API = getAPI('admin');
    const url = `/pricing/payee/payment-config/all?payee_business_id=${bizId}`;
    const headers = {
      Authorization: `Bearer ${userSessionKeyStore}`,
    };
    setIsLoading(true);
    API.get(url, { headers })
      .then(({ data }) => {
        if (!data?.data?.[0]) {
          enqueueSnackbar('Something went wrong, data format is incorrect or no data found. Please contact Admin', { variant: 'error' });
          console.error(data);
          return;
        }

        const conf = data.data[0];

        setNotDue(conf?.payment_config);
        setOverDue(conf?.overdue_payment_config);
        setLod(conf?.lod_config);
      })
      .catch((err) => {
        const errMsg = getErrMessageUtil(err);
        enqueueSnackbar(errMsg, { variant: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllFees();
  }, []);

  if (!notDue?.length && !overDue?.length && !lod?.length && !isLoading) {
    return (
      <Typography
        variant="h6"
        sx={{ p: 2 }}
        color="error"
      >
        Something is wrong with this payee data, no payment config found
      </Typography>
    );
  }

  return (
    <Box>
      <Typography variant="h4" sx={{ mt: 4 }}>Payer (Debtor) Fee</Typography>
      {notDue?.length > 0 && <UserBizNotDueTable data={notDue} bizId={bizId} getAllFees={getAllFees} userType={'payer'} />}
      {overDue?.length > 0 && <UserBizOverDueTable data={overDue} bizId={bizId} getAllFees={getAllFees} userType={'payer'} />}
      {lod?.length > 0 && <UserBizFeeLodTable data={lod} bizId={bizId} getAllFees={getAllFees} userType={'payer'} />}
    </Box>
  );
}
