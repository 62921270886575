import * as React from 'react';
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  styled,
  Toolbar,
  Typography,
  Chip,
  Stack,
} from '@mui/material';
import COLORS from 'constants/COLORS';
import { ExitToAppTwoTone } from '@mui/icons-material';
import LAYOUT from 'constants/LAYOUT';
import { useStoreAuth } from 'store/auth-store';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { parseJwt } from 'util/util';

const { APP_BAR_HEIGHT } = LAYOUT;

const MENU = [
  {
    title: 'Users',
    to: '/admin/user',
  },
  {
    title: 'Invoices',
    to: '/admin/invoice',
  },
];

export default function AdminLayoutHeader() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const sessionToken = localStorage.getItem('session_token') || null;
  const jwtTokenPayload = parseJwt(sessionToken);

  const resetAuthStorageAndStore = useStoreAuth((s) => s.resetAuthStorageAndStore);

  return (
    <AraAppBar
      position="fixed"
      sx={{
        color: COLORS.labelPrimary,
      }}
    >
      <AraToolbar disableGutters sx={{ py: 0, px: 4 }}>
        <Avatar src="/images/avatar.png" sx={{ width: 30, height: 30 }}>AA</Avatar>
        <Typography sx={{ ml: 1 }} variant="caption">
          Admin
          {' '}
          (
          {jwtTokenPayload?.user?.email || ''}
          )
        </Typography>
        <Stack direction="row" spacing={1} ml={4}>
          {MENU.map((item) => {
            const selected = !!window.location.pathname.includes(item.to);
            return (
              <a href={item.to} key={item.to}>
                <Chip
                  sx={{ mr: 1 }}
                  label={item.title}
                  variant={selected ? 'filled' : 'outlined'}
                  color="primary"
                  key={item.to}
                  onClick={() => {
                    navigate(item.to);
                  }}
                />
              </a>
            );
          })}
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <IconButton
            sx={{
              border: '1px solid #E0E3E7',
              borderRadius: '10px',

            }}
            color="inherit"
            onClick={() => {
              resetAuthStorageAndStore();
              navigate('/auth');
              enqueueSnackbar('You have been logged out', { variant: 'success' });
            }}
            edge="end"
          >
            <ExitToAppTwoTone color="primary" fontSize="small" />
          </IconButton>
        </Box>

      </AraToolbar>
    </AraAppBar>

  );
}

const AraAppBar = styled(AppBar, {

})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: 'rgb(100 116 139 / 12%) 0px 1px 4px',
  height: APP_BAR_HEIGHT,
  borderBottom: `1px solid ${COLORS.divider}`,
  backgroundColor: '#fff',
}));

const AraToolbar = styled(Toolbar, {

})(() => ({
  height: APP_BAR_HEIGHT,
}));
