/* eslint-disable react/no-unstable-nested-components */

import React, { useState } from 'react';
import { Edit } from '@mui/icons-material';
import {

  Box,
  Chip,
  Tooltip,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';

import { useSnackbar } from 'notistack';
import { UpdateOnePaymentMethodAPI } from 'services/pricing-api-services';
import AraDataTable from 'components/shared/AraDataTable';
import { compact, groupBy } from 'lodash';
import { formatAmountUtil } from 'util/util';
import NotDueModal from './NotDueModal';

export default function NotDueTable({
  data, bizId, getAllFees, userType,
}) {
  let isDataNotFound = false;

  const groupDataByPaymentMode = groupBy(data, (d) => d.payment_mode);
  const transformedData = Object.entries(groupDataByPaymentMode).map(([paymentMode, dataArrays]) => {
    const d = dataArrays[0];
    if (!d?.[userType]?.transaction_fees) {
      isDataNotFound = true;
      console.error(d);
      return null;
    }

    return {
      payment_mode: paymentMode,
      payex_identifier: dataArrays.map((p) => p?.payex_identifier).join(', '),
      gateway: d.gateway,
      transaction_fees: d[userType].transaction_fees,
      is_payment_mode_blocked: d.is_payment_mode_blocked,
    };
  });
  const cleanData = compact(transformedData);

  // --------------------------------

  const { enqueueSnackbar } = useSnackbar();

  const [updateFee, setUpdateFee] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const updateNotDuePaymentMethodStatus = async (isChecked, rowData) => {
    setIsUpdating(true);

    const requestBody = {
      payment_methods: {
        [rowData.payment_mode]: {
          modify: true,
          enabled: isChecked,
        },
      },
      type: 'not_due',
      payee_business_id: bizId,
    };

    const [status] = await UpdateOnePaymentMethodAPI(requestBody);
    setIsUpdating(false);

    if (status) {
      getAllFees();
      enqueueSnackbar(`Succesfully ${isChecked ? 'Activated' : 'Blocked'} Not Due Payment Mode:  ${rowData.payment_mode} `, {
        variant: 'success',
      });
    }
  };

  return (
    <Box sx={{ px: 4, my: 10 }}>
      {updateFee != null && (
        <NotDueModal
          getAllFees={getAllFees}
          bizId={bizId}
          updateFee={updateFee}
          open={updateFee != null}
          handleClose={() => {
            setUpdateFee(null);
          }}
          userType={userType}
        />
      )}
      <Typography variant="h6" sx={{ mb: 1 }}>
        Not Due
      </Typography>
      {isDataNotFound && (
        <Typography color="error">
          There are incorrect/dirty data, please check console error and inform Admin
        </Typography>
      )}
      <AraDataTable
        title=""
        data={cleanData}
        columns={getColumns(cleanData, isUpdating, updateNotDuePaymentMethodStatus, setUpdateFee, userType)}
        options={{
          selectableRows: 'none',
          responsive: 'standard',
          pagination: false,
          download: false,
          print: false,
          viewColumns: false,
          search: false,
          filter: false,
          sort: false,
          elevation: 0,
        }}
      />
    </Box>
  );
}

const getColumns = (data, isUpdating, updateNotDuePaymentMethodStatus, setUpdateFee, userType) => {
  const col = [
    {
      name: 'payment_mode',
      label: 'Payment Mode',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'payex_identifier',
      label: 'Identifier',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'gateway',
      label: 'Gateway',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'transaction_fees',
      label: 'Transaction Fee',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const rowData = data[dataIndex];

          return rowData.transaction_fees.map((tx, txIdx) => {
            const amount = tx?.amount?.[0]?.fixed_amount || 0;
            const percentageAmount = tx?.amount?.[0]?.percentage_amount || 0;

            return (
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'} sx={{ mt: txIdx === 0 ? 0 : 1.5 }} key={tx.identifier}>
                <Typography>{`${tx?.name}: RM ${formatAmountUtil(amount)} + ${percentageAmount}%`}</Typography>
                <Tooltip title={`Update ${tx.name} Fee`} arrow>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setUpdateFee({ ...tx, payment_mode: rowData.payment_mode });
                      // setSelectedData(rowData);
                    }}
                    sx={{ ml: 1 }}
                  >
                    <Edit fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          });
        },
      },
    },

  ];

  if (userType === 'payee') {
    col.push({
      name: 'is_payment_mode_blocked',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (value ? (
          <Chip
            label="Blocked"
            color="error"
            size="small"
            sx={{ fontWeight: 700 }}
          />
        ) : (
          <Chip
            label="Active"
            color="success"
            size="small"
            sx={{ fontWeight: 700 }}
          />
        )),
      },
    });

    col.push({
      name: 'Action',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const rowData = data[dataIndex];

          return (
            <Box>
              <Tooltip
                title={
                  rowData?.is_payment_mode_blocked
                    ? 'Active Payment Method'
                    : 'Block Payment Method'
                }
                arrow
              >
                <Switch
                  disabled={isUpdating}
                  checked={
                    !rowData?.is_payment_mode_blocked
                  }
                  onClick={(e) => {
                    updateNotDuePaymentMethodStatus(e.target.checked, rowData);
                  }}
                  name="checkedB"
                  color="success"
                />
              </Tooltip>
            </Box>
          );
        },
      },
    });
  }

  return col;
};
