/* eslint-disable react/no-unstable-nested-components */

import React, { useState } from 'react';
import { Edit } from '@mui/icons-material';
import {

  Box,
  Chip,
  Tooltip,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';

import AraDataTable from 'components/shared/AraDataTable';
import { compact } from 'lodash';
import { formatAmountUtil } from 'util/util';
import LodModal from './LodModal';

export default function LodTable({
  data, bizId, getAllFees, userType,
}) {
  let isDataNotFound = false;

  const transformedData = data.map((d) => {
    if (!d?.[userType]?.transaction_fees) {
      isDataNotFound = true;
      console.error(d);
      return null;
    }
    return {
      lod_type: d.type,
      transaction_fees: d[userType].transaction_fees,
      is_payment_mode_blocked: d.is_lod_method_blocked,
    };
  });

  const cleanData = compact(transformedData);

  // --------------------------------

  const [updateFee, setUpdateFee] = useState(null);

  return (
    <Box sx={{ px: 4, my: 10 }}>
      {updateFee != null && (
        <LodModal
          getAllFees={getAllFees}
          bizId={bizId}
          updateFee={updateFee}
          open={updateFee != null}
          handleClose={() => {
            setUpdateFee(null);
          }}
          userType={userType}
        />
      )}
      <Typography variant="h6" sx={{ mb: 1 }}>
        LoD
      </Typography>
      {isDataNotFound && (
        <Typography color="error">
          There are incorrect/dirty data, please check console error and inform Admin
        </Typography>
      )}
      <AraDataTable
        title=""
        data={cleanData}
        columns={getColumns(cleanData, setUpdateFee, userType)}
        options={{
          selectableRows: 'none',
          responsive: 'standard',
          pagination: false,
          download: false,
          print: false,
          viewColumns: false,
          search: false,
          filter: false,
          sort: false,
          elevation: 0,
        }}
      />
    </Box>
  );
}

const getColumns = (data, setUpdateFee, userType) => {
  const col = [
    {
      name: 'lod_type',
      label: 'LoD Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'transaction_fees',
      label: 'Transaction Fee',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const rowData = data[dataIndex];

          return rowData.transaction_fees.map((tx, txIdx) => {
            const amount = tx?.amount?.[0]?.fixed_amount || 0;
            const percentageAmount = tx?.amount?.[0]?.percentage_amount || 0;

            return (
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'} sx={{ mt: txIdx === 0 ? 0 : 1.5 }} key={tx.identifier}>
                <Typography>{`${tx?.name}: RM ${formatAmountUtil(amount)} + ${percentageAmount}%`}</Typography>
                <Tooltip title={`Update ${tx.name} Fee`} arrow>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setUpdateFee({ ...tx, lod_type: rowData.lod_type });
                    }}
                    sx={{ ml: 1 }}
                  >
                    <Edit fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          });
        },
      },
    },

  ];

  if (userType === 'payee') {
    col.push({
      name: 'is_payment_mode_blocked',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (value ? (
          <Chip
            label="Blocked"
            color="error"
            size="small"
            sx={{ fontWeight: 700 }}
          />
        ) : (
          <Chip
            label="Active"
            color="success"
            size="small"
            sx={{ fontWeight: 700 }}
          />
        )),
      },
    });

    col.push({
      name: 'Action',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const rowData = data[dataIndex];

          return (
            <Box>
              <Switch
                disabled
                checked={
                  !rowData?.is_payment_mode_blocked
                }
                name="checkedB"
                color="success"
              />
              <Typography>LoD config is always active :)</Typography>
            </Box>
          );
        },
      },
    });
  }

  return col;
};
