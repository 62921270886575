/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
// MUI components
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,

} from '@mui/material';

// MUI icons
import {
  AccountTree,
  KeyboardArrowDown,
  Receipt,
} from '@mui/icons-material';

import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

export default function InvoiceActionButton({
  rowData = null,
}) {
  const disableDrMessage = () => {
    if (rowData?.invoice_status === 'paid' && rowData?.debt_restructuring_object?.status === 'active') {
      return '';
    }

    if (!['overdue'].includes(rowData.invoice_status)) {
      return 'Business Requirement: Invoice is not Overdue';
    }

    return '';
  };

  const getActionButtonLists = () => {
    if (!rowData) return [];

    const list = [];

    if (rowData?.attachment) {
      list.push({
        icon: <Receipt fontSize="small" color="primary" />,
        label: 'Download Invoice Attachment',
        onClick: () => {
          window.open(rowData?.attachment, '_blank', 'noreferrer');
        },
      });
    }

    list.push({
      icon: <AccountTree fontSize="small" color="secondary" />,
      label: 'Debt Restructuring',
      onClick: () => {
        window.open(`/admin/debt-restructure/${rowData._id}`, '_blank', 'noreferrer');
      },
      disabled: disableDrMessage(),
      tooltip: disableDrMessage(),
    });

    return list;
  };

  return (
    <PopupState variant="popover" popupId="action-button-popup-popover">
      {(popupState) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            endIcon={<KeyboardArrowDown />}
            {...bindTrigger(popupState)}
          >
            Action
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <List sx={{ minWidth: 300, p: 0 }}>
              {getActionButtonLists().map((l, i) => (
                <React.Fragment key={`${i}`}>
                  {l?.custom != null ? l.custom : <NewListItem {...l} />}
                  <Divider />
                </React.Fragment>
              ))}

            </List>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

function NewListItem(
  {
    disabled = false, onClick = () => { }, icon = <Box />, label = '', tooltip = '', color = '#000',
  },
) {
  return (
    <ListItem disablePadding>
      <Tooltip title={tooltip} arrow>
        <span style={{ width: '100%' }}>
          <ListItemButton
            disabled={disabled}
            onClick={onClick}
          >
            <ListItemIcon sx={{ mr: 1.5, minWidth: 0 }}>
              {icon}
            </ListItemIcon>
            <ListItemText primary={label} sx={{ color }} />
          </ListItemButton>
        </span>
      </Tooltip>
    </ListItem>
  );
}
