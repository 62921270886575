/* eslint-disable react/no-unstable-nested-components */

import React, { useState } from 'react';
import { Edit } from '@mui/icons-material';
import {

  Box,
  Chip,
  Tooltip,
  IconButton,
  Switch,
  Typography,
  Button,
} from '@mui/material';

import { useSnackbar } from 'notistack';
import { UpdateOnePaymentMethodAPI } from 'services/pricing-api-services';
import AraDataTable from 'components/shared/AraDataTable';
import {
  compact, flattenDeep, groupBy,
} from 'lodash';
import { formatAmountUtil } from 'util/util';
import OverDueModal from './OverDueModal';
import OverDueManyModal from './OverDueManyModal';

export default function OverDueTable({
  data, bizId, getAllFees, userType,
}) {
  let isDataNotFound = false;
  let transformedData = data.map((o) => {
    const { payment_config: configuration, tier } = o;

    const groupDataByPaymentMode = groupBy(configuration, (d) => d.payment_mode);

    return Object.entries(groupDataByPaymentMode).map(([paymentMode, dataArrays]) => {
      const d = dataArrays[0];
      if (!d?.[userType]?.transaction_fees) {
        isDataNotFound = true;
        console.error({ ...d, tier });
        return null;
      }

      return {
        tier,
        payment_mode: paymentMode,
        payex_identifier: dataArrays.map((p) => p?.payex_identifier).join(', '),
        gateway: d.gateway,
        transaction_fees: d[userType].transaction_fees,
        is_payment_mode_blocked: d.is_payment_mode_blocked,
      };
    });
  });
  transformedData = flattenDeep(transformedData);
  const cleanData = compact(transformedData);

  // --------------------------------

  const { enqueueSnackbar } = useSnackbar();

  const [updateFee, setUpdateFee] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateAllTier, setUpdateAllTier] = useState(null);
  const ALL_FEE_TEMP_DATA = userType === 'payee' ? { identifier: 'default_fees', name: 'Comission' } : { identifier: 'service_fees', name: 'Service Fee' };

  const updateOverduePaymentMethodStatus = async (isChecked, rowData) => {
    setIsUpdating(true);

    const requestBody = {
      overdue_tiers: {
        [rowData.tier]: {
          modify: true,
          payment_methods: {
            [rowData.payment_mode]: {
              modify: true,
              enabled: isChecked,
            },
          },
        },
      },
      type: 'overdue',
      payee_business_id: bizId,
    };

    const [status] = await UpdateOnePaymentMethodAPI(requestBody);
    setIsUpdating(false);

    if (status) {
      getAllFees();
      enqueueSnackbar(`Succesfully ${isChecked ? 'Activated' : 'Blocked'} ${rowData.payment_mode} `, {
        variant: 'success',
      });
    }
  };

  return (
    <Box sx={{ px: 4, my: 10 }}>
      {updateFee != null && (
        <OverDueModal
          getAllFees={getAllFees}
          bizId={bizId}
          updateFee={updateFee}
          open={updateFee != null}
          handleClose={() => {
            setUpdateFee(null);
          }}
          userType={userType}
        />
      )}
      {updateAllTier != null && (
        <OverDueManyModal
          getAllFees={getAllFees}
          bizId={bizId}
          updateAllTier={updateAllTier}
          open={updateAllTier != null}
          handleClose={() => {
            setUpdateAllTier(null);
          }}
          userType={userType}
          tiers={data.map((d) => d.tier)}
        />
      )}

      <Typography variant="h6" sx={{ mb: 1 }}>
        Overdue
      </Typography>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Button
          onClick={() => {
            setUpdateAllTier(ALL_FEE_TEMP_DATA);
          }}
          variant="contained"
          sx={{ my: 2 }}
        >
          Update All
          {' '}
          {ALL_FEE_TEMP_DATA.name}
        </Button>

      </Box>
      {isDataNotFound && (
        <Typography color="error">
          There are incorrect/dirty data, please check console error and inform Admin
        </Typography>
      )}
      <AraDataTable
        title=""
        data={cleanData}
        columns={getColumns(cleanData, isUpdating, updateOverduePaymentMethodStatus, setUpdateFee, userType)}
        options={{
          selectableRows: 'none',
          responsive: 'standard',
          pagination: true,
          download: false,
          print: false,
          viewColumns: false,
          search: false,
          filter: false,
          sort: false,
          rowsPerPage: 3,
          rowsPerPageOptions: [3, 12],
          elevation: 0,
        }}
      />
    </Box>
  );
}

const getColumns = (data, isUpdating, updateOverduePaymentMethodStatus, setUpdateFee, userType) => {
  const col = [
    {
      name: 'tier',
      label: 'Tier',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'payment_mode',
      label: 'Payment Mode',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'payex_identifier',
      label: 'Identifier',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'gateway',
      label: 'Gateway',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'transaction_fees',
      label: 'Transaction Fee',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const rowData = data[dataIndex];

          return rowData.transaction_fees.map((tx, txIdx) => {
            const amount = tx?.amount?.[0]?.fixed_amount || 0;
            const percentageAmount = tx?.amount?.[0]?.percentage_amount || 0;

            return (
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'} sx={{ mt: txIdx === 0 ? 0 : 1.5 }} key={tx.identifier}>
                <Typography>{`${tx?.name}: RM ${formatAmountUtil(amount)} + ${percentageAmount}%`}</Typography>
                <Tooltip title={`Update ${tx.name} Fee`} arrow>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setUpdateFee({ ...tx, payment_mode: rowData.payment_mode, tier: rowData.tier });
                    }}
                    sx={{ ml: 1 }}
                  >
                    <Edit fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          });
        },
      },
    },

  ];

  if (userType === 'payee') {
    col.push({
      name: 'is_payment_mode_blocked',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (value ? (
          <Chip
            label="Blocked"
            color="error"
            size="small"
            sx={{ fontWeight: 700 }}
          />
        ) : (
          <Chip
            label="Active"
            color="success"
            size="small"
            sx={{ fontWeight: 700 }}
          />
        )),
      },
    });

    col.push({
      name: 'Action',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const rowData = data[dataIndex];

          return (
            <Box>
              <Tooltip
                title={
                  rowData?.is_payment_mode_blocked
                    ? 'Active Payment Method'
                    : 'Block Payment Method'
                }
                arrow
              >
                <Switch
                  disabled={isUpdating}
                  checked={
                    !rowData?.is_payment_mode_blocked
                  }
                  onClick={(e) => {
                    updateOverduePaymentMethodStatus(e.target.checked, rowData);
                  }}
                  name="checkedB"
                  color="success"
                />
              </Tooltip>
            </Box>
          );
        },
      },
    });
  }

  return col;
};
