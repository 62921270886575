import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import COLORS from 'constants/COLORS';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
} from 'react-router-dom';
import App from './App';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const MUI_THEME = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.red,
      light: COLORS.redLight,
    },
    background: {
      default: COLORS.mainBG,
    },
    text: {
      primary: COLORS.labelPrimary,
      secondary: COLORS.labelSecondary,
    },
    divider: COLORS.divider,
    success: {
      main: COLORS.green,
      light: COLORS.greenLight,
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Inter ,sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          '& ::-webkit-scrollbar': {
            width: '0.5em',
          },
          '& ::-webkit-scrollbar-track': {
            background: COLORS.divider,
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#BEC1C5',
            borderRadius: 5,
          },
          '& ::-webkit-scrollbar-thumb:hover': {},
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root'),
);
root.render(
  <BrowserRouter>
    <ThemeProvider theme={MUI_THEME}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <App />
      </LocalizationProvider>
    </ThemeProvider>
  </BrowserRouter>,
);
