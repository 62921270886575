// LIBRARIES & PACKAGES
import React, { useState, useEffect, Suspense } from 'react';
import {
  Outlet, Route, Routes, useNavigate,
} from 'react-router-dom';
import { Button } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import { useStoreAuth } from 'store/auth-store';
// COMPONENTS & CONSTANTS
import './App.css';
import COLORS from 'constants/COLORS';
// import FONT_SIZE from 'constants/FONT_SIZE';
import FullScreenLoader from 'components/shared/FullScreenLoader/FullScreenLoader';
import SHADOWS from 'constants/SHADOWS';
// SCREENS
import AuthScreen from 'screens/Auth/AuthScreen';
import NotFoundScreen404 from 'screens/NotFoundScreen404';
import SplashScreen from 'screens/SplashScreen';
import AdminLayout from 'screens/Admin/AdminLayout';

import UserLayout from 'screens/Admin/User/UserLayout';
import UserBizLayout from 'screens/Admin/User/UserBiz/UserBizLayout';
import UserBizHomeScreen from 'screens/Admin/User/UserBiz/UserBizHomeScreen';
import UserBizLodRequestScreen from 'screens/Admin/User/UserBiz/(UserBizLod)/UserBizLodRequestScreen';
import UserBizLodOneScreen from 'screens/Admin/User/UserBiz/(UserBizLod)/UserBizLodOneScreen';
import UserBizLodTwoScreen from 'screens/Admin/User/UserBiz/(UserBizLod)/UserBizLodTwoScreen';
import ComingSoonScreen from './screens/ComingSoonScreen';
import DebtRestructureOneInvoiceScreen from 'screens/Admin/DebtRestructure/DebtRestructureOneInvoiceScreen';
import UserBizFeePayeeScreen from 'screens/Admin/User/UserBiz/(UserBizFee)/UserBizFeePayeeScreen';
import UserBizFeePayerScreen from 'screens/Admin/User/UserBiz/(UserBizFee)/UserBizFeePayerScreen';
import UserScreen from 'screens/Admin/User/page';
import InvoiceScreen from 'screens/Admin/Invoice/page';

function App() {
  const navigate = useNavigate();

  const {
    setIsAuthStore,
    setUserSessionKeyStore,
    resetAuthStorageAndStore,
  } = useStoreAuth();

  const [isLoaded, setIsLoaded] = useState(false);

  const handleAuthProfile = () => {
    const sessionToken = localStorage.getItem('session_token');

    if (!sessionToken) {
      setIsLoaded(true);
      resetAuthStorageAndStore();
      navigate('/auth');
      return;
    }
    setIsAuthStore(true);
    setUserSessionKeyStore(sessionToken);

    setIsLoaded(true);
    const pathName = window.location.pathname;
    if (!pathName || pathName === '/' || pathName === '/auth') {
      navigate('/auth');
    }
  };

  useEffect(() => {
    handleAuthProfile();
  }, []);

  const notistackRef = React.useRef(); // or React.createRef
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return isLoaded ? (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={4}
      action={
        // eslint-disable-next-line
        (key) => (
          <Button
            onClick={onClickDismiss(key)}
            sx={{ color: '#fff', fontSize: 10, mt: 0.2 }}
            size="small"
          >
            Dismiss
          </Button>
        )
      }
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .SnackbarItem-variantError': {
          color: '#fff',
          bgcolor: COLORS.red,
          boxShadow: SHADOWS.listBox,
        },
      }}
    >
      <Suspense fallback={<FullScreenLoader isLoading />}>
        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route
              index
              element={<SplashScreen handleAuthProfile={handleAuthProfile} />}
            />
            <Route path="auth" element={<AuthScreen />} />
            <Route path="admin" element={<AdminLayout />}>
              <Route path="user" element={<Outlet />}>
                <Route index element={<UserScreen />} />
                <Route path=":userId" element={<UserLayout />}>
                  <Route path=":bizId" element={<UserBizLayout />}>
                    <Route path="home" element={<UserBizHomeScreen />} />
                    <Route path="fee-payee" element={<UserBizFeePayeeScreen />} />
                    <Route path="fee-payer" element={<UserBizFeePayerScreen />} />
                    <Route path="lod-request" element={<UserBizLodRequestScreen />} />
                    <Route path="lod-one" element={<UserBizLodOneScreen />} />
                    <Route path="lod-two" element={<UserBizLodTwoScreen />} />
                  </Route>
                </Route>
              </Route>
              <Route path="invoice" element={<Outlet />}>
                <Route index element={<InvoiceScreen />} />
              </Route>
              <Route path="payment" element={<ComingSoonScreen />} />
              <Route path="debt-restructure" element={<Outlet />}>
                <Route path=":invoiceId" element={<DebtRestructureOneInvoiceScreen />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFoundScreen404 />} />
          </Route>
        </Routes>
      </Suspense>
    </SnackbarProvider>

  ) : (
    <FullScreenLoader isLoading />
  );
}

export default App;
