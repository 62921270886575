/* eslint-disable no-alert */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { formatAmountUtil } from 'util/util';

export default function ActiveDetailTable({ debtRestructuring }) {
  const { payer_info: payerInfo, invoice_number: invoiceNumber } = debtRestructuring.invoice;

  const payerName = `${payerInfo?.first_name} ${payerInfo?.last_name}`;

  return (
    <TableContainer>
      <Table sx={{ width: 'fit-content' }} size="small">
        <TableBody>
          <TableRow>
            <TableCell>{payerName}</TableCell>
            <TableCell>{invoiceNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{payerInfo?.email}</TableCell>
            <TableCell>
              {payerInfo?.phone_number}
              {' '}
              or
              {' '}
              {payerInfo?.office_number}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Full Debt Amount</TableCell>
            <TableCell>
              RM
              {formatAmountUtil(debtRestructuring.amount.total_debt_amount + debtRestructuring.charges.current_charges.absolute_amount)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Penalty Per Day</TableCell>
            <TableCell>
              RM
              {formatAmountUtil(debtRestructuring?.charges?.charges_config?.[0]?.charge_per_day)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Final To Pay</TableCell>
            <TableCell>
              RM
              {formatAmountUtil(debtRestructuring?.amount?.amount_due)}
            </TableCell>
          </TableRow>
          <TableRow><TableCell /></TableRow>
          <TableRow><TableCell /></TableRow>
          <TableRow sx={{ 'td, th': { fontWeight: 'bold' } }}>
            <TableCell />
            <TableCell align="left">Payment status</TableCell>
            <TableCell align="left">Payment due date</TableCell>
            <TableCell align="right">Debt amt</TableCell>
            <TableCell align="right">Penalty/late fees</TableCell>
            <TableCell align="left">Total (Before Mark as paid)</TableCell>
            <TableCell align="right">Final (After Mark as paid)</TableCell>
          </TableRow>
          {debtRestructuring.repayments.map((re) => (
            <TableRow
              key={re._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {`Repayment ${re.repayment_index}`}
                &nbsp;&nbsp;
              </TableCell>
              <TableCell align="left"><Typography variant="caption">{re?.payment_status}</Typography></TableCell>
              <TableCell align="left">
                <Typography whiteSpace={'pre-line'} variant="body2">
                  {format(new Date(re.due_date), 'MMM d, yyyy')}
                </Typography>
                <Typography whiteSpace={'pre-line'} variant="caption">
                  {format(new Date(re.due_date), 'p, zzz')}
                </Typography>

              </TableCell>
              <TableCell align="right">{`RM ${formatAmountUtil(re?.amount.total_debt_amount)}`}</TableCell>
              <TableCell align="right">{`RM ${formatAmountUtil(re?.charges?.current_charges?.absolute_amount)}`}</TableCell>
              <TableCell align="left">{`RM ${formatAmountUtil((re?.amount.total_debt_amount || 0) + (re?.charges?.current_charges?.absolute_amount || 0))}`}</TableCell>

              <TableCell align="right">{`RM ${formatAmountUtil(re?.amount?.amount_due)}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
