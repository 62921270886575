import { create } from 'zustand';

const initialDsStore = {
  refreshDsStore: Date.now(),
};

export const useStoreDs = create((set) => ({
  ...initialDsStore,
  TriggerRefreshDsStore: () => set({ refreshDsStore: Date.now() }),
}));
