/* eslint-disable no-alert */
/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from 'react';
import {
  Box, Button, Tooltip, Typography,
} from '@mui/material';
import {
  Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent, timelineOppositeContentClasses,
} from '@mui/lab';

import AraDataTable from 'components/shared/AraDataTable';
import { format } from 'date-fns';
import { TimerOutlined } from '@mui/icons-material';
import { find } from 'lodash';
import COLORS from 'constants/COLORS';
import { formatAmountUtil } from 'util/util';
import { postPauseResumeManyLodAPI, postUpdateManyInvoiceEscalationAPI } from 'services/lod-api-services';
import UserBizLodActionButton from './ActionButton';

export default function UserBizLodTable({
  invoices, LOD_TYPE, getAvailableLodInvoices, setIsLoading,
}) {
  const [selectedRowsIndex, setSelectedRowIndex] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    name: 'current_lod_created_at',
    direction: 'desc',
  });

  const data = useMemo(() => invoices.map((inv) => {
    const currentLod = find(inv.lods, (lod) => lod.type === LOD_TYPE);

    return {
      current_lod: currentLod,
      current_lod_created_at: currentLod?.created_at,
      current_lod_issued_date: currentLod?.issued_date,
      ...inv,
    };
  }), [invoices, LOD_TYPE]);

  const columns = [
    {
      name: 'invoice_number',
      label: 'Invoice Number',
      options: {
        sort: false,
        searchable: true,
        customBodyRenderLite: (i) => {
          const finalData = data[i];

          if (!finalData) return 'Something went wrong, no payer';

          const getMessage = () => {
            let hasEscalation = null;
            let nextLod = null;

            if (finalData?.current_lod?.type === 'lod_1') {
              hasEscalation = finalData.escalation.includes('lod_2');
              nextLod = 'lod_2';
            }

            if (finalData?.current_lod?.type === 'lod_2') {
              hasEscalation = finalData.escalation.includes('wos');
              nextLod = 'wos';
            }

            if (finalData?.current_lod?.is_paused === true) {
              return 'No LoD Escalation as LoD reminder is paused';
            }

            if (hasEscalation) {
              return `Invoice will be automatically escalated to ${nextLod}`;
            }

            return 'No Escalation';
          };

          return (
            <Box>

              <Box display="flex" alignItems="center" flexDirection="row">
                <Typography variant="caption">
                  {finalData?.current_lod?.type}
                  {' '}
                  ,
                  {' '}
                  {finalData?.current_lod?.lod_number}
                </Typography>
                {' '}
                {!finalData?.current_lod?.issued_date
                  && (
                    <Tooltip title="Pending Signature" arrow>
                      <TimerOutlined sx={{ color: COLORS.ara2, fontSize: 14, ml: 1 }} />
                    </Tooltip>
                  )}
              </Box>
              <Typography variant="body2" fontWeight="bold">
                {finalData?.invoice_number}
              </Typography>
              <Typography variant="caption">
                {getMessage()}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: 'payer_info',
      label: 'Payer / Debtor',
      options: {
        sort: false,
        searchable: true,
        customBodyRenderLite: (i) => {
          const payerInfo = invoices[i]?.payer_info;

          if (!payerInfo) return 'Something went wrong, no payer';

          return (
            <Box>
              <Typography variant="caption">{payerInfo?.user_type}</Typography>

              <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                {payerInfo.user_type === 'business' ? `${payerInfo?.business_name} \n ${payerInfo.email}`
                  : `${payerInfo?.first_name} ${payerInfo?.last_name} \n ${payerInfo.email}`}
              </Typography>

            </Box>
          );
        },
      },
    },
    {
      name: 'payer_info',
      label: 'Payer Number',
      options: {
        sort: false,
        searchable: true,
        customBodyRenderLite: (i) => {
          const payerInfo = invoices[i]?.payer_info;

          if (!payerInfo) return 'Something went wrong, no payer';

          return (
            <Box>
              <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                <b>Phone Number:</b>
                {'\n'}
                {payerInfo?.phone_number}
                {'\n\n'}
                <b>SMS Number:</b>
                {'\n'}
                {payerInfo?.office_number || '-'}

              </Typography>

            </Box>
          );
        },
      },
    },
    {
      name: 'current_lod_created_at',
      label: 'Requested At',
      options: {
        sort: true,
        searchable: false,
        customBodyRenderLite: (i) => {
          const lodCreatedAt = data[i]?.current_lod_created_at != null ? new Date(data[i]?.current_lod_created_at) : null;

          if (!lodCreatedAt) return '-';

          return (
            <Box>
              <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                {format(lodCreatedAt, 'dd MMM yyyy')}
                {'\n'}
                <small>
                  {format(lodCreatedAt, 'p')}
                  {'\n'}
                  {format(lodCreatedAt, 'zzz')}
                </small>
              </Typography>

            </Box>
          );
        },
      },
    },
    {
      name: 'current_lod_issued_date',
      label: 'Signed At',
      options: {
        sort: true,
        searchable: false,
        customBodyRenderLite: (i) => {
          const lodIssuedDate = data[i]?.current_lod_issued_date != null ? new Date(data[i]?.current_lod_issued_date) : null;

          if (!lodIssuedDate) return '-';

          return (
            <Box>
              <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                {format(lodIssuedDate, 'dd MMM yyyy')}
                {'\n'}
                <small>
                  {format(lodIssuedDate, 'p')}
                  {'\n'}
                  {format(lodIssuedDate, 'zzz')}
                </small>
              </Typography>

            </Box>
          );
        },
      },
    },

    {
      name: 'processable_value',
      label: 'Value (MYR)',
      options: {
        sort: true,
        searchable: false,
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRenderLite: (i) => (
          <Typography>
            {formatAmountUtil(data[i]?.processable_value)}
          </Typography>
        ),
      },
    },

    {
      name: 'Action',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const rowData = data[dataIndex];

          return (
            <Box>
              <UserBizLodActionButton rowData={rowData} />
            </Box>
          );
        },
      },
    },
    {
      name: 'wamessages',
      label: 'OttoWA',
      options: {
        sort: false,
        searchable: false,
        customBodyRenderLite: (i) => (
          <Box>
            {data[i]?.wamessages != null && data[i]?.wamessages?.length > 0
              && <WaTimeline wamessages={data[i].wamessages} />}
          </Box>
        ),
      },
    },
  ];

  return (
    <AraDataTable
      title=""
      data={data}
      columns={columns}
      options={{
        selectableRows: 'multiple',
        // selectableRowsOnClick: true,
        onRowSelectionChange: (currentRowsSelectedIndex, allRowsSelected, rowsSelected) => {
          setSelectedRowIndex([...rowsSelected]);
        },
        responsive: 'standard',
        pagination: true,
        rowsPerPage: 9999,
        rowsPerPageOptions: [20, 40, 80, 9999],
        download: false,
        print: false,
        viewColumns: false,
        search: true,
        filter: false,
        sort: true,
        searchOpen: true,
        sortOrder,
        onColumnSortChange: (name, direction) => {
          // Update the sortOrder state with the new sort order
          setSortOrder({
            name,
            direction,
          });
        },
        elevation: 0,
        textLabels: {
          body: {
            noMatch: `There are no ${LOD_TYPE} here`,
          },
        },
        customSearch,
        customToolbarSelect: () => (
          <Box>
            <Button
              variant="outlined"
              size="small"
              onClick={async () => {
                if (!selectedRowsIndex || selectedRowsIndex?.length < 1) return;

                if (!window.confirm(`Confirm escalate ${selectedRowsIndex.length} invoices?`)) {
                  return;
                }

                const finalData = selectedRowsIndex.map((rowIndex) => data[rowIndex]);
                setIsLoading(true);
                await postUpdateManyInvoiceEscalationAPI(finalData.map((f) => f._id), LOD_TYPE === 'lod_1' ? ['lod_2'] : ['lod_2', 'wos']);
                setIsLoading(false);
                getAvailableLodInvoices();
              }}
            >
              {`Resume Escalation to ${LOD_TYPE === 'lod_1' ? 'LoD 2' : 'WoS'}`}
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              sx={{ ml: 2, mr: 6 }}
              onClick={async () => {
                if (!selectedRowsIndex || selectedRowsIndex?.length < 1) return;

                if (!window.confirm(`Confirm Stop escalation for ${selectedRowsIndex.length} invoices?`)) {
                  return;
                }

                const finalData = selectedRowsIndex.map((rowIndex) => data[rowIndex]);
                setIsLoading(true);
                await postUpdateManyInvoiceEscalationAPI(finalData.map((f) => f._id), []);
                setIsLoading(false);
                getAvailableLodInvoices();
              }}
            >
              {`Pause Escalation to ${LOD_TYPE === 'lod_1' ? 'LoD 2' : 'WoS'}`}
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={async () => {
                if (!selectedRowsIndex || selectedRowsIndex?.length < 1) return;

                if (!window.confirm(`Confirm RESUME LoD for ${selectedRowsIndex.length} invoices?`)) {
                  return;
                }

                const finalInvoiceData = selectedRowsIndex.map((rowIndex) => data[rowIndex]);

                const finalLodIds = finalInvoiceData.map((inv) => inv?.current_lod?._id);

                if (!finalLodIds) return;

                setIsLoading(true);
                await postPauseResumeManyLodAPI(finalLodIds, 'resume');
                setIsLoading(false);
                getAvailableLodInvoices();
              }}
            >
              Resume LoD Reminders
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              sx={{ m: 2 }}
              onClick={async () => {
                if (!selectedRowsIndex || selectedRowsIndex?.length < 1) return;

                if (!window.confirm(`Confirm PAUSE LoD for ${selectedRowsIndex.length} invoices?`)) {
                  return;
                }

                const finalInvoiceData = selectedRowsIndex.map((rowIndex) => data[rowIndex]);

                const finalLodIds = finalInvoiceData.map((inv) => inv?.current_lod?._id);

                if (!finalLodIds) return;

                setIsLoading(true);
                await postPauseResumeManyLodAPI(finalLodIds, 'pause');
                setIsLoading(false);
                getAvailableLodInvoices();
              }}
            >
              Pause LoD Reminders
            </Button>
          </Box>
        ),
      }}
    />
  );
}

const customSearch = (searchQuery, currentRow) => {
  const [invoiceNumber, payerInfo] = currentRow;

  const lowerCaseQuery = searchQuery.toLowerCase();

  const {
    business_name: businessName,
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    office_number: officeNumber,
    email,
  } = payerInfo || {};

  const searchFields = [businessName, `${firstName} ${lastName}`, phoneNumber, officeNumber, email, invoiceNumber];

  return searchFields.some(
    (field) => field && field?.toLowerCase().includes(lowerCaseQuery),
  );
};

function WaTimeline({ wamessages }) {
  const [isExpand, setIsExpand] = useState(false);

  return isExpand ? (
    <>
      <Box textAlign="center">
        <Button size="small" onClick={() => { setIsExpand(false); }}>Collapse</Button>
      </Box>
      <Timeline
        sx={{
          p: 0,
          m: 0,
          // Adjust font size for TimelineOppositeContent
          [`& .${timelineOppositeContentClasses.root}`]: {
            fontSize: 10,
          },
          // Remove padding for TimelineItem
          [`& .${timelineOppositeContentClasses.root} + .${timelineOppositeContentClasses.root}`]: {
            m: 0,
          },
          // Make the timeline smaller
          '& .MuiTimelineItem-missingOppositeContent': {
            '&:before': {
              display: 'none',
            },
          },
        }}
      >
        {wamessages.map((wa) => (
          <TimelineItem key={wa?._id}>
            {wa?.created_at != null
              && (
                <TimelineOppositeContent color="textSecondary" sx={{ fontSize: 10, whiteSpace: 'pre-line' }}>
                  {format(new Date(wa?.created_at), 'dd MMM yyyy') || '-'}
                  {'\n'}
                  {format(new Date(wa?.created_at), 'p') || '-'}
                </TimelineOppositeContent>
              )}
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ fontSize: 11, whiteSpace: 'pre-line' }}>
              {wa?.identifier}
              {'\n'}
              {wa?.status}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  ) : (
    <Button size="small" onClick={() => { setIsExpand(true); }}>
      Expand
      {' '}
      {`(${wamessages.length})`}
    </Button>
  );
}
