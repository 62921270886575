/* eslint-disable no-alert */
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Container,
  Divider,
  styled, Typography,
} from '@mui/material';
import COLORS from 'constants/COLORS';
import LAYOUT from 'constants/LAYOUT';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { activateOneDebtRestructuringAPI, getOneDebtRePaymentLinkAPI } from 'services/debtrestructuring-api-services';
import { useStoreDs } from 'store/debtRestructure-store';
import ActiveDetailTable from './ActiveDetailTable';
import { find } from 'lodash';
import ActiveMarkPaidModal from './ActiveMarkPaidModal';
import ActiveMarkPaidTimeline from './ActiveMarkPaidTimeline';

export default function DebtRestructureOneInvoiceActive({ invoice }) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentLink, setPaymentLink] = useState(null);
  const [isMarkPaid, setIsMarkPaid] = useState(false);

  const { TriggerRefreshDsStore } = useStoreDs();

  const handleDelete = async () => {
    if (!invoice?.debt_restructuring?._id) return;

    if (find(invoice?.debt_restructuring?.repayments, (re) => re?.payment_status === 'succeeded' || re?.payment_status === 'processing') != null) {
      enqueueSnackbar('Not allowed to deactivate as 1 of the Repayment payment are processing or succeeded', { variant: 'info' });
      return;
    }

    if (invoice?.debt_restructuring?.amount?.mark_as_paid_amount > 0) {
      enqueueSnackbar('Not allowed to deactivate, as this debt restructuring has been previously marked as paid.', { variant: 'info' });
      return;
    }

    const confirm = window.prompt('Type \'confirm\' if you\'re sure to Deactivate Debt Restructure');

    if (!confirm || confirm !== 'confirm') {
      enqueueSnackbar('No problem, your action is canceled', { variant: 'info' });
      return;
    }

    setIsDeleting(true);
    await activateOneDebtRestructuringAPI(invoice?.debt_restructuring?._id);
    setIsDeleting(false);
    TriggerRefreshDsStore();
  };

  const handleGetPaymentLink = async () => {
    setIsLoading(true);
    const [status, link] = await getOneDebtRePaymentLinkAPI(invoice?.debt_restructuring?._id);
    setIsLoading(false);

    if (!status) return;

    setPaymentLink(link);

    navigator.clipboard.writeText(link)
      .then(() => {
        enqueueSnackbar(`Copied ${link} to clipboard`, { variant: 'success' });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(`Error copying link ${link} to clipboard`, { variant: 'error' });
      });
  };

  return (
    <>
      {invoice?.debt_restructuring?._id != null && isMarkPaid
      && (
      <ActiveMarkPaidModal
        payeeBusinessId={invoice.business_id}
        debtRestructuring={invoice.debt_restructuring}
        open={isMarkPaid}
        handleClose={() => {
          setIsMarkPaid(false);
        }}
      />
      )}
      <FullScreenContainer
        maxWidth="false"
        disableGutters
        sx={{ p: 5 }}
      >
        <Typography variant="h6">
          {'Active Debt Restructure'}
        </Typography>
        <Box display={'flex'} flexDirection={'row'} mt={2}>
          <LoadingButton
            variant="contained"
            color="error"
            size="small"
            onClick={handleDelete}
            loading={isDeleting}
          >
            Deactivate Debt Restructure
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="info"
            size="small"
            sx={{ ml: 10 }}
            disabled={paymentLink != null}
            onClick={handleGetPaymentLink}
            loading={isLoading}
          >
            Get Payment Link
          </LoadingButton>
          {paymentLink != null
          && (
            <Typography sx={{ ml: 2 }}>
              {paymentLink}
            </Typography>
          )}

        </Box>

        <Divider sx={{ my: 3 }} />

        <ActiveDetailTable debtRestructuring={invoice.debt_restructuring} />
        <Divider sx={{ my: 3 }} />
        <Box height={50} />
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setIsMarkPaid(true)}
          loading={isLoading}
        >
          Mark as paid
        </LoadingButton>

        {invoice?.debt_restructuring?.amount?.mark_as_paid?.length != null && invoice?.debt_restructuring?.amount?.mark_as_paid.length > 0
        && <ActiveMarkPaidTimeline markAsPaidArr={invoice?.debt_restructuring?.amount?.mark_as_paid} />}
        <Box height={300} />
      </FullScreenContainer>
    </>
  );
}

const FullScreenContainer = styled(Container)(() => ({
  minHeight: `calc(100vh - ${LAYOUT.APP_BAR_HEIGHT}px)`,
  backgroundColor: COLORS.secondaryBG,
}));
