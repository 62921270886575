import { findIndex } from 'lodash';
import { create } from 'zustand';

const initialLodStoreState = {
  lodInvoicesStore: [],
};

export const useStoreLod = create((set, get) => ({
  ...initialLodStoreState,
  setLodInvoicesStore: (data) => set({ lodInvoicesStore: data }),
  updateOneLodInvoicesStore: (newData) => {
    const { lodInvoicesStore } = get();

    const tempArr = lodInvoicesStore.slice();

    const index = findIndex(tempArr, { _id: newData._id });
    if (index >= 0) {
      const tempObj = tempArr[index];
      const editedObj = Object.assign(tempObj, newData);

      tempArr.splice(index, 1, editedObj);
    }
    set({ lodInvoicesStore: [...tempArr] });
  },
}));
