import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import COLORS from 'constants/COLORS';
import MUIDataTable from 'mui-datatables';
import SHADOWS from 'constants/SHADOWS';

export default function AraDataTable(props) {
  return (
    <ThemeProvider theme={getMuiTheme}>
      <MUIDataTable
        {...props}
      />
    </ThemeProvider>
  );
}

const getMuiTheme = () => createTheme({
  palette: {
    type: 'light',
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.red,
      light: COLORS.redLight,
    },
    background: {
      default: COLORS.mainBG,
    },
    text: {
      primary: COLORS.labelPrimary,
      secondary: COLORS.labelSecondary,
    },
    divider: COLORS.divider,
    success: {
      main: COLORS.green,
      light: COLORS.greenLight,
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Inter ,sans-serif',
    fontSize: 12,
  },
  components: {
    MUIDataTable: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',

          '.tss-1cdcmys-MUIDataTable-responsiveBase': {
            borderRadius: '8px',
            boxShadow: SHADOWS.primary,
          },
          '.tss-5jmwmz-MUIDataTableFooter-root': {
            '.MuiTableRow-root': {
              borderRadius: '20px',
              background: '#fff',
              boxShadow: SHADOWS.primary,
            },
          },
          '.tss-xfsg3u-MUIDataTableSelectCell-expandDisabled': {
            display: 'none !important',
          },
        },
      },
    },
    MUIDataTableSelectCell: {
      expandDisabled: {
        // Soft hide the button.
        visibility: 'hidden',
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          background: '#fff',
          margin: '10px 0 40px 0',
          padding: 20,
          borderRadius: '8px',
          boxShadow: SHADOWS.primary,
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          '.MuiTableCell-root': {
            padding: 0,
            background: 'none !important',
            borderRadius: '0 !important',
            boxShadow: 'none !important',
            borderBottom: 'none',
          },
          '.MuiToolbar-root': {
            margin: '10px 0 0 0 !important',
            padding: '0 !important',
            boxShadow: 'none !important',
            background: 'none !important',
          },
          '.MuiTableRow-root': {
            background: 'none !important',
            borderRadius: '0 !important',
            boxShadow: 'none !important',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: 'rgb(55, 65, 81) !important',
          textTransform: 'uppercase',
          fontWeight: 700,
          padding: 10,
          fontSize: 10,
          backgroundColor: 'rgb(243,244,246) !important',
          borderTop: '1px solid #F0F3F6',
          marginTop: 20,
        },
        body: {
          fontWeight: 500,
          padding: 10,
          borderBottom: '1px solid #F0F3F6',
          background: '#fff',
        },
      },
    },
  },
});
