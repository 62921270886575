import React, { useEffect, useState } from 'react';
import { useStoreAuth } from 'store/auth-store';
import { getAPI } from 'util/API';
import {
  Paper, Tab, Tabs,
  CircularProgress,
  Box,
} from '@mui/material';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { getErrMessageUtil } from 'util/util';
import { enqueueSnackbar } from 'notistack';

export default function UserLayout() {
  const { userId, bizId } = useParams();
  const navigate = useNavigate();
  const [selectedBizId, setSelectedBizId] = useState(bizId);

  const { userSessionKeyStore } = useStoreAuth();
  const [verifiedBusinessData, setVerifiedBusinessData] = useState([]);

  const getVerifiedBusinesses = async () => {
    const API = getAPI('admin');
    const headers = {
      Authorization: `Bearer ${userSessionKeyStore}`,
    };
    const url = `/user/get/many/verified?user_id=${userId}`;
    API.get(url, { headers })
      .then((res) => {
        const { verifiedBusinessAccounts } = res.data.data;

        setVerifiedBusinessData(verifiedBusinessAccounts);
        if (!selectedBizId) {
          handleSetSelectedBizId(verifiedBusinessAccounts[0].account_id);
        }
      })
      .catch((err) => {
        const errMessage = getErrMessageUtil(err);
        enqueueSnackbar(errMessage, { variant: 'error' });
      });
  };

  const handleSetSelectedBizId = (bId) => {
    if (bId) {
      setSelectedBizId(bId);
      navigate(`/admin/user/${userId}/${bId}/home`);
    }
  };

  useEffect(() => {
    if (!userId) return;

    getVerifiedBusinesses();
  }, [userId]);

  return verifiedBusinessData != null && verifiedBusinessData?.length > 0 ? (
    <>
      <Paper>
        <Tabs
          value={selectedBizId}
          onChange={(e, newValue) => {
            handleSetSelectedBizId(newValue);
          }}
        >
          {verifiedBusinessData.map((v) => <Tab key={v.account_id} label={`${v.account_name} ( ${v.email} )`} value={v.account_id} />)}
        </Tabs>
      </Paper>
      <Outlet />
    </>
  ) : (
    <Box width="100%" textAlign="center" pt={5}>
      <CircularProgress />
    </Box>
  );
}
