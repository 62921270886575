import { enqueueSnackbar } from 'notistack';

// STORES
import { SecureAdminAxios } from 'util/API';
import { getErrMessageUtil } from 'util/util';

export const UpdateOnePaymentMethodAPI = async (body) => {
  const url = '/pricing/payee/payment-methods/update';

  try {
    const res = await SecureAdminAxios.put(url, body);

    return [true, res.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const UpdateOneTransactionFeeAPI = async (body) => {
  const url = '/pricing/payee/transaction-fees/update';

  try {
    const res = await SecureAdminAxios.put(url, body);

    return [true, res];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};
