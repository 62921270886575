/* eslint-disable no-alert */
import React from 'react';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import { formatAmountUtil } from 'util/util';
import { LoadingButton } from '@mui/lab';
import { useStoreDs } from 'store/debtRestructure-store';
import { activateOneDebtRestructuringAPI } from 'services/debtrestructuring-api-services';
import { enqueueSnackbar } from 'notistack';

export default function DraftStepThreeConfirm({ debtRestructuring }) {
  const { payer_info: payerInfo, invoice_number: invoiceNumber } = debtRestructuring.invoice;

  const payerName = `${payerInfo?.first_name} ${payerInfo?.last_name}`;

  const { TriggerRefreshDsStore } = useStoreDs();

  const handleActivate = async () => {
    const confMsg = "Please type 'confirm' to proceed with the activation of Debt Restructure. Note: Debtor will receive a notification upon activation.";
    const confirm = window.prompt(confMsg);

    if (!confirm || confirm !== 'confirm') {
      enqueueSnackbar('No problem, your action is canceled', { variant: 'info' });
      return;
    }

    await activateOneDebtRestructuringAPI(debtRestructuring._id);
    TriggerRefreshDsStore();
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ width: 'fit-content' }} size="small">
          <TableBody>
            <TableRow>
              <TableCell>{payerName}</TableCell>
              <TableCell>{invoiceNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{payerInfo?.email}</TableCell>
              <TableCell>
                {payerInfo?.phone_number}
                {' '}
                or
                {' '}
                {payerInfo?.office_number}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Full Debt Amount</TableCell>
              <TableCell>
                RM
                {formatAmountUtil(debtRestructuring?.amount?.total_debt_amount)}
              </TableCell>
            </TableRow>

            <TableRow><TableCell /></TableRow>
            <TableRow><TableCell /></TableRow>
            <TableRow sx={{ 'td, th': { fontWeight: 'bold' } }}>
              <TableCell />
              <TableCell align="right">Payment due date</TableCell>
              <TableCell align="right">Debt amt</TableCell>
              <TableCell align="right">Penalty/late fees</TableCell>
              <TableCell align="right">Final to pay</TableCell>
            </TableRow>
            {debtRestructuring.repayments.map((re) => (
              <TableRow
                key={re._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {`Payment ${re.repayment_index}`}
                  &nbsp;&nbsp;
                </TableCell>
                <TableCell align="right">{format(new Date(re.due_date), 'MMM d, yyyy')}</TableCell>
                <TableCell align="right">{`RM ${formatAmountUtil(re?.amount.processable_value)}`}</TableCell>
                <TableCell align="right">{`RM ${formatAmountUtil(re?.charges?.current_charges?.absolute_amount)}`}</TableCell>
                <TableCell align="right">{`RM ${formatAmountUtil(re?.amount?.amount_due)}`}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={5}><Divider /></TableCell>

            </TableRow>
            <TableRow>
              <TableCell colSpan={2} />
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>{`RM ${formatAmountUtil(debtRestructuring?.amount?.total_debt_amount)}`}</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>{`RM ${formatAmountUtil(debtRestructuring?.charges?.current_charges?.absolute_amount)}`}</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                {`RM ${formatAmountUtil(debtRestructuring?.amount?.amount_due)}`}
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
      <LoadingButton variant="contained" sx={{ mt: 4 }} size="small" color="success" onClick={handleActivate}>Activate Debt Restructure</LoadingButton>
    </>
  );
}
