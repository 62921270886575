import {
  FormHelperText,
  InputLabel, MenuItem, TextField, FormControl, Select,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { } from 'react';
import * as Yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import { createOneDebtRestructuringAPI } from 'services/debtrestructuring-api-services';
import { useStoreDs } from 'store/debtRestructure-store';

export default function DraftStepOneCreate({ invoice }) {
  const { TriggerRefreshDsStore } = useStoreDs();

  const formik = useFormik({
    initialValues: {
      number_of_repayments: '',
      duration_of_repayment: 30,
      penalty_fee_type: 'abs',
      penalty_fee: 0,
      first_repayment_date: new Date(),
    },
    validationSchema: Yup.object().shape({
      number_of_repayments: Yup
        .number()
        .required('Number of repayments is required')
        .min(1, 'Minimum 1 number of repayment')
        .max(200, 'Maximum 200 number of repayments'),
      duration_of_repayment: Yup
        .number()
        .required('Duration of repayments is required')
        .min(1, 'Minimum 1 duration of repayment'),
      penalty_fee_type: Yup.string().required('Penalty Fees Type is required')
        .oneOf(['pct', 'abs'], 'Invalid Penalty Fees Type'),
      penalty_fee: Yup.number().when('penalty_fee_type', {
        is: 'pct',
        then: Yup.number()
          .min(0, 'Transaction Fees Amount must be greater than or equal to 0')
          .max(100, 'Transaction Fees Amount must be less than or equal to 100')
          .required('Transaction Fees Amount is required'),
        otherwise: Yup.number()
          .min(0, 'Transaction Fees Amount must be greater than or equal to 0')
          .required('Transaction Fees Amount is required'),
      }),
      first_repayment_date: Yup
        .date('Invalid Date')
        .typeError('Invalid Date')
        .required('First repayment date is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const [status] = await createOneDebtRestructuringAPI({ ...values, invoice_ids: [invoice._id] });
      setSubmitting(false);

      if (!status) return;

      TriggerRefreshDsStore();
    },

  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    isSubmitting,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid2 container spacing={4}>
          <Grid2 xs={3}>
            <TextField
              fullWidth
              label="Number of repayments"
              type="number"
              size="small"
              {...getFieldProps('number_of_repayments')}
              error={Boolean(touched.number_of_repayments && errors.number_of_repayments)}
              helperText={touched.number_of_repayments && errors.number_of_repayments}
            />
          </Grid2>
          <Grid2 xs={3}>
            <TextField
              fullWidth
              label="Duration of repayments (day)"
              type="number"
              size="small"
              {...getFieldProps('duration_of_repayment')}
              error={Boolean(touched.duration_of_repayment && errors.duration_of_repayment)}
              helperText={touched.duration_of_repayment && errors.duration_of_repayment}
            />
          </Grid2>
          <Grid2 xs={2}>
            <FormControl
              fullWidth
              error={Boolean(
                touched.penalty_fee_type && errors.penalty_fee_type,
              )}
            >
              <InputLabel id="type">
                Penalty Fees Type
              </InputLabel>
              <Select
                {...getFieldProps('penalty_fee_type')}
                label="Penalty Fees Type"
                size="small"
              >
                <MenuItem disabled value="">
                  Select Penalty Fee Type
                </MenuItem>
                <MenuItem value="pct">Percentage</MenuItem>
                <MenuItem value="abs">Absolute</MenuItem>
              </Select>
              <FormHelperText>
                {touched.penalty_fee_type && errors.penalty_fee_type ? errors.penalty_fee_type : null}
              </FormHelperText>
            </FormControl>

          </Grid2>
          <Grid2 xs={4}>
            <TextField
              fullWidth
              label={`Penalty Fee (${formik.values?.penalty_fee_type || ''}) - 30 days`}
              type="number"
              size="small"
              {...getFieldProps('penalty_fee')}
              error={Boolean(touched.penalty_fee && errors.penalty_fee)}
              helperText={touched.penalty_fee && errors.penalty_fee}
            />
          </Grid2>
          <Grid2 xs={3}>
            <DatePicker
              fullWidth
              label={`First Repayment Date ( ${format(new Date(), 'zzzz')} )`}
              format="dd/MM/yyyy"
              value={formik.values.first_repayment_date}
              onChange={(value) => setFieldValue('first_repayment_date', value, true)}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  error: Boolean(errors.first_repayment_date),
                  helperText: errors.first_repayment_date,
                  size: 'small',
                },
              }}
            />
          </Grid2>

        </Grid2>
        <LoadingButton type="submit" variant="contained" sx={{ mt: 2 }} loading={isSubmitting} size="small">Create Debt Restructure</LoadingButton>
      </Form>
    </FormikProvider>
  );
}
