/* eslint-disable no-alert */
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Container,
  Divider,
  styled, Typography,
} from '@mui/material';
import COLORS from 'constants/COLORS';
import LAYOUT from 'constants/LAYOUT';
import React, { useState } from 'react';
import { deleteOneDebtRestructuringAPI } from 'services/debtrestructuring-api-services';
import { useStoreDs } from '../../../../store/debtRestructure-store';
import DraftStepOneCreate from './DraftStepOneCreate';
import DraftStepThreeConfirm from './DraftStepThreeConfirm';
import DraftStepTwoUpdate from './DraftStepTwoUpdate';

export default function DebtRestructureOneInvoiceDraft({ invoice }) {
  const [isDeleting, setIsDeleting] = useState(false);

  const { TriggerRefreshDsStore } = useStoreDs();

  const handleDelete = async () => {
    if (!invoice?.debt_restructuring?._id) return;

    const alert = window.confirm('Are you sure to delete/reset Debt Restructure?');

    if (!alert) return;

    setIsDeleting(true);
    await deleteOneDebtRestructuringAPI(invoice?.debt_restructuring?._id);
    setIsDeleting(false);
    TriggerRefreshDsStore();
  };

  return (
    <FullScreenContainer
      maxWidth="false"
      disableGutters
      sx={{ p: 5 }}
    >
      <Typography variant="h6">
        {`Debt Restructure Draft | ${invoice.invoice_number} | MYR ${invoice.processable_value}`}
      </Typography>

      {invoice?.debt_restructuring?._id != null && (
        <LoadingButton
          variant="contained"
          color="error"
          size="small"
          sx={{ mt: 2 }}
          onClick={handleDelete}
          loading={isDeleting}
        >
          Delete/Reset Draft
        </LoadingButton>
      )}
      <Divider sx={{ my: 8 }} />
      <Typography variant="h6" fontWeight={'bold'} sx={{ mb: 5 }}>
        {`Step 1 - ${invoice?.debt_restructuring?._id == null ? 'Required' : 'Completed'}`}
      </Typography>
      {invoice?.debt_restructuring?._id == null && <DraftStepOneCreate invoice={invoice} />}

      {invoice?.debt_restructuring?.repayments != null && (
        <>
          <Divider sx={{ my: 8 }} />
          <Box display={'flex'} alignItems="center" flexDirection={'center'} mb={5}>
            <Typography variant="h6" fontWeight={'bold'} sx={{ mr: 2 }}>Step 2 (Optional)</Typography>
          </Box>

          <DraftStepTwoUpdate debtRestructuring={invoice.debt_restructuring} />

          <Divider sx={{ my: 8 }} />
          <Typography variant="h6" fontWeight={'bold'} sx={{ mb: 5 }}>
            Step 3
          </Typography>
          <DraftStepThreeConfirm debtRestructuring={invoice.debt_restructuring} />
        </>
      )}
      <Box height={300} />
    </FullScreenContainer>
  );
}

const FullScreenContainer = styled(Container)(() => ({
  minHeight: `calc(100vh - ${LAYOUT.APP_BAR_HEIGHT}px)`,
  backgroundColor: COLORS.secondaryBG,
}));
