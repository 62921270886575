/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import {
  Box, Button, CircularProgress, IconButton, Tooltip, Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { getLodInvoicesAPI } from 'services/lod-api-services';
import AraDataTable from 'components/shared/AraDataTable';
import { format } from 'date-fns';
import { Receipt } from '@mui/icons-material';
import { UserBizLodRequestModal } from 'components/User/UserBiz/UserBizLodRequest';
import { formatAmountUtil } from 'util/util';

export default function UserBizLodRequestScreen() {
  const { bizId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowsIndex, setSelectedRowIndex] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [invoices, setInvoices] = useState([]);

  const getAvailableLodInvoices = async () => {
    setIsLoading(true);

    const [status, data] = await getLodInvoicesAPI(bizId, 'request');

    setIsLoading(false);

    if (!status) return;

    setInvoices(data.invoices);
  };

  useEffect(() => {
    if (!bizId) return;

    getAvailableLodInvoices();
  }, [bizId]);

  const columns = [
    {
      name: 'invoice_number',
      label: 'Invoice Number',
      options: {
        sort: false,
        searchable: true,
      },
    },
    {
      name: 'payer_info',
      label: 'Payer/Debtor',
      options: {
        sort: false,
        searchable: true,
        customBodyRenderLite: (i) => {
          const payerInfo = invoices[i]?.payer_info;

          if (!payerInfo) return 'Something went wrong, no payer';

          return (
            <Box>
              <Typography variant="caption">{payerInfo?.user_type}</Typography>

              <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                {payerInfo.user_type === 'business' ? `${payerInfo?.business_name} \n ${payerInfo.email}`
                  : `${payerInfo?.first_name} ${payerInfo?.last_name} \n ${payerInfo.email}`}
              </Typography>

            </Box>
          );
        },
      },
    },
    {
      name: 'payer_info',
      label: 'Payer Number',
      options: {
        sort: false,
        searchable: true,
        customBodyRenderLite: (i) => {
          const payerInfo = invoices[i]?.payer_info;

          if (!payerInfo) return 'Something went wrong, no payer';

          return (
            <Box>
              <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                Phone Number:
                {' '}
                {payerInfo?.phone_number}
                {'\n'}
                SMS Number:
                {' '}
                {payerInfo?.office_number || '-'}

              </Typography>

            </Box>
          );
        },
      },
    },
    {
      name: 'due_date',
      label: 'Due Date',
      options: {
        sort: true,
        searchable: false,
        customBodyRenderLite: (i) => `${format(new Date(invoices[i]?.due_date || new Date()), 'dd-MMM-yy')}`,
      },

    },
    {
      name: 'invoice_status',
      label: 'Invoice Status',
      options: {
        sort: false,
        searchable: false,
      },
    },
    {
      name: 'processable_value',
      label: 'Value (MYR)',
      options: {
        sort: true,
        searchable: false,
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRenderLite: (i) => (
          <Typography>
            {formatAmountUtil(invoices[i]?.processable_value)}
          </Typography>
        ),
      },
    },
    {
      name: 'Action',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const rowData = invoices[dataIndex];

          return (
            <Box>
              <Tooltip title="Invoice Attachment" arrow>
                <IconButton
                  aria-label="delete"
                  size="small"
                  color="primary"
                  onClick={() => {
                    window.open(rowData?.attachment, '_blank', 'noreferrer');
                  }}
                >
                  <Receipt fontSize="inherit" />
                </IconButton>
              </Tooltip>

            </Box>
          );
        },
      },
    },
  ];

  return bizId && !isLoading ? (
    <>
      {selectedInvoices != null && selectedInvoices?.length > 0
        && (
          <UserBizLodRequestModal
            open={selectedInvoices != null && selectedInvoices?.length > 0}
            handleClose={() => setSelectedInvoices([])}
            selectedInvoices={selectedInvoices}
            getAvailableLodInvoices={getAvailableLodInvoices}
          />
        )}
      <Box p={4}>
        <AraDataTable
          title=""
          data={invoices}
          columns={columns}
          options={{
            selectableRows: 'multiple',
            // selectableRowsOnClick: true,
            onRowSelectionChange: (currentRowsSelectedIndex, allRowsSelected, rowsSelected) => {
              setSelectedRowIndex([...rowsSelected]);
            },
            responsive: 'standard',
            pagination: true,
            rowsPerPage: 9999,
            rowsPerPageOptions: [20, 40, 80, 9999],
            download: false,
            print: false,
            viewColumns: false,
            search: true,
            filter: false,
            sort: true,
            searchOpen: true,
            sortOrder: {
              name: 'processable_value',
              direction: 'desc',
            },
            elevation: 0,
            textLabels: {
              body: {
                noMatch: 'There are no invoices available for the LoD request. In order to issue an LoD, the invoices must be overdue and have not been issued.',
              },
            },
            customToolbarSelect: () => (
              <Button
                variant="contained"
                size="large"
                sx={{ m: 2 }}
                onClick={() => {
                  const finalData = selectedRowsIndex.map((rowIndex) => invoices[rowIndex]);
                  setSelectedInvoices([...finalData]);
                }}
              >
                Request LOD
              </Button>
            ),
            customSearch,
          }}
        />
      </Box>
    </>
  ) : (
    <Box width="100%" textAlign="center" pt={5}>
      <CircularProgress />
    </Box>
  );
}

const customSearch = (searchQuery, currentRow) => {
  const [invoiceNumber, payerInfo] = currentRow;

  const lowerCaseQuery = searchQuery.toLowerCase();

  const {
    business_name: businessName,
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    office_number: officeNumber,
    email,
  } = payerInfo || {};

  const searchFields = [businessName, `${firstName} ${lastName}`, phoneNumber, officeNumber, email, invoiceNumber];

  return searchFields.some(
    (field) => field && field?.toLowerCase().includes(lowerCaseQuery),
  );
};
