/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-undef */
import {

  Box,
  TextField, Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  Form, FormikProvider, useField, useFormik,
} from 'formik';
import React, { } from 'react';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { updateOneDebtRestructuringAPI } from 'services/debtrestructuring-api-services';
import { useStoreDs } from 'store/debtRestructure-store';
import { enqueueSnackbar } from 'notistack';
import { grey } from '@mui/material/colors';
import { formatAmountUtil } from 'util/util';
import { Info } from '@mui/icons-material';
import { round } from 'lodash';

export default function DraftStepTwoUpdate({ debtRestructuring }) {
  const { TriggerRefreshDsStore } = useStoreDs();

  const initValue = debtRestructuring.repayments.map((re) => ({
    _id: re._id,
    amount_due: re?.amount?.processable_value,
    penalty_fee: re?.charges?.current_charges?.absolute_amount ?? '',
    due_date: new Date(re?.due_date),
  }));

  const formik = useFormik({
    initialValues: {
      repayments: initValue,
    },
    validationSchema: Yup.object().shape({
      repayments: Yup.array(
        Yup.object({
          amount_due: Yup
            .number()
            .required('Amount is required')
            .min(1, 'Minimum 1'),
          penalty_fee: Yup
            .number()
            .required('Penalty Fee is required'),

        }),
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const [status] = await updateOneDebtRestructuringAPI(debtRestructuring?._id, values);
      setSubmitting(false);

      if (!status) return;

      TriggerRefreshDsStore();
      enqueueSnackbar('Succesfully updated', { variant: 'success' });
    },

  });

  const {

    handleSubmit,
    isSubmitting,
  } = formik;

  return (
    <>
      <Grid2 container spacing={4} sx={{ mb: 8 }}>
        <ContentGrid label="Total Invoice Amount" value={formatAmountUtil(debtRestructuring?.amount?.total_debt_amount)} />
        <ContentGrid label="Penalty Type" value={formatAmountUtil(debtRestructuring?.charges?.charges_config?.[0]?.name)} />
        <ContentGrid label="Penalty Per Day" value={`MYR ${formatAmountUtil(debtRestructuring?.charges?.charges_config?.[0]?.charge_per_day)}`} />
        <Grid2 xs={12}>
          <Typography>
            <Info color="primary" />
            {' '}
            {' '}
            Delete/Reset Draft if you would like to change Penalty Per Day and Number of Repayments
          </Typography>
        </Grid2>
      </Grid2>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {formik.values?.repayments != null && formik.values.repayments.map((repayment, index) => (
            <Repayment
              key={index}
              index={index}
            />
          ))}
          <Grid2 container spacing={4} mb={2}>
            <Grid2 xs={2}>Total</Grid2>
            <Grid2 xs={3}>
              {`MYR ${formatAmountUtil(formik?.values?.repayments.reduce((acc, re) => acc + round(re?.amount_due || 0, 2), 0))}`}
            </Grid2>
            <Grid2 xs={3}>
              {`MYR ${formatAmountUtil(formik?.values?.repayments.reduce((acc, re) => acc + round(re?.penalty_fee || 0, 2), 0))}`}
            </Grid2>
          </Grid2>
          <LoadingButton type="submit" variant="outlined" size="small" loading={isSubmitting}>Update Debt Restructure (Optional)</LoadingButton>
        </Form>
      </FormikProvider>

    </>
  );
}

function Repayment({
  index,
}) {
  const [amountDueField, amountDueMeta] = useField({ name: `repayments.${index}.amount_due` });
  const [penaltyFeeField, penaltyFeeMeta] = useField({ name: `repayments.${index}.penalty_fee` });
  const [dueDateField, dueDatemeta, dueDateHelpers] = useField({ name: `repayments.${index}.due_date` });

  return (

    <Grid2 container spacing={4} mb={2}>
      <Grid2 xs={2}>
        <Typography>
          Repayment
          {' '}
          {index + 1}
          :
        </Typography>
      </Grid2>
      <Grid2 xs={3}>
        <TextField
          onWheel={(e) => e.target.blur()}
          fullWidth
          type="number"
          label={'Amount (MYR)'}
          {...amountDueField}
          error={amountDueMeta.touched && Boolean(amountDueMeta.error)}
          helperText={amountDueMeta.touched && amountDueMeta.error}
          size="small"
        />
      </Grid2>
      <Grid2 xs={3}>
        <TextField
          onWheel={(e) => e.target.blur()}
          fullWidth
          type="number"
          label={'Penalty Fee (MYR)'}
          {...penaltyFeeField}
          error={penaltyFeeMeta.touched && Boolean(penaltyFeeMeta.error)}
          helperText={penaltyFeeMeta.touched && penaltyFeeMeta.error}
          size="small"
        />
      </Grid2>
      <Grid2 xs={4}>
        <DatePicker
          label={`Due Date ( ${format(new Date(), 'zzzz')} )`}
          format="dd/MM/yyyy"
          {...dueDateField}
          onChange={(value) => dueDateHelpers.setValue(value)}
          slotProps={{
            textField: {
              variant: 'outlined',
              error: Boolean(dueDatemeta.first_repayment_date),
              helperText: dueDatemeta.first_repayment_date,
              size: 'small',
            },
          }}
        />
      </Grid2>

    </Grid2>
  );
}

function ContentGrid({
  label, value,
}) {
  return (
    <Grid2
      xs={12}
      sm={4}
      md={4}
      lg={3}
      sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'column' }, justifyContent: 'space-between' }}
    >
      <Box display="flex" alignItems="center" flexDirection="row">
        <Typography variant="body2" sx={{ color: grey[600] }}>
          {label}
        </Typography>
      </Box>

      <Typography sx={{ fontWeight: 600, wordBreak: 'break-word' }}>{value}</Typography>
    </Grid2>
  );
}
