/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from 'react';
// MUI components
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  styled,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';

// MUI icons
import {
  AccountTree,
  Escalator,
  KeyboardArrowDown, Receipt, WhatsApp,
} from '@mui/icons-material';

import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { CustomLodIcon } from 'components/shared/Icons/CustomLodIcon';
import { putPauseResumeOneLodAPI, putUpdateOneInvoiceEscalationAPI } from 'services/lod-api-services';
import { useStoreLod } from 'store/lod-store';
import { find } from 'lodash';

export default function UserBizLodActionButton({
  rowData = null,
}) {
  const getActionButtonLists = () => {
    if (!rowData) return [];

    const list = [];

    if (rowData?.attachment) {
      list.push({
        icon: <Receipt fontSize="small" color="primary" />,
        label: 'Download Invoice Attachment',
        onClick: () => {
          window.open(rowData?.attachment, '_blank', 'noreferrer');
        },
      });
    }

    if (rowData?.current_lod?.unsigned_url) {
      list.push({
        icon: <CustomLodIcon fontSize="small" color="inherit" />,
        label: 'Download Unsigned LoD',
        onClick: () => {
          window.open(rowData?.current_lod?.unsigned_url, '_blank', 'noreferrer');
        },
      });
    }

    if (rowData?.current_lod?.signed_url) {
      list.push({
        icon: <CustomLodIcon fontSize="small" color="secondary" />,
        label: 'Download Signed LoD',
        onClick: () => {
          window.open(rowData?.current_lod?.signed_url, '_blank', 'noreferrer');
        },
      });
    }

    if (rowData?.current_lod != null) {
      list.push({
        custom: <>
          <PauseResumeLodWA rowData={rowData} />
          <EscaladeLod rowData={rowData} />
        </>,
      });
    }

    list.push({
      icon: <AccountTree fontSize="small" color="secondary" />,
      label: 'Debt Restructuring',
      onClick: () => {
        window.open(`/admin/debt-restructure/${rowData._id}`, '_blank', 'noreferrer');
      },
      disabled: !rowData?.current_lod?.issued_date,
      tooltip: !rowData?.current_lod?.issued_date ? 'Debt restructuring is only available for a Letter of Demand (LoD) that has been signed.' : '',
    });

    return list;
  };

  return (
    <PopupState variant="popover" popupId="action-button-popup-popover">
      {(popupState) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            endIcon={<KeyboardArrowDown />}
            {...bindTrigger(popupState)}
          >
            Action
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <List sx={{ minWidth: 300, p: 0 }}>
              {getActionButtonLists().map((l, i) => (
                <React.Fragment key={`${i}`}>
                  {l?.custom != null ? l.custom : <NewListItem {...l} />}
                  <Divider />
                </React.Fragment>
              ))}

            </List>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

function PauseResumeLodWA({ rowData = null }) {
  const [isLoading, setIsLoading] = useState(false);

  const { lodInvoicesStore, updateOneLodInvoicesStore } = useStoreLod();

  const handlePauseResume = async () => {
    setIsLoading(true);
    const [status, data] = await putPauseResumeOneLodAPI(rowData?.current_lod?._id);

    setIsLoading(false);

    if (!status) return;

    const isPause = data[0].is_paused;

    const updatedInvoice = find(lodInvoicesStore, (inv) => inv?._id.toString() === rowData?._id.toString());

    const newLods = updatedInvoice.lods.map((lod) => ({ ...lod, is_paused: isPause }));

    updatedInvoice.lods = newLods;

    updateOneLodInvoicesStore(updatedInvoice);
  };

  return (
    <ListItem>
      <ListItemIcon sx={{ mr: 1.5, minWidth: 0 }}>
        <WhatsApp />
      </ListItemIcon>
      <ListItemText primary="LoD Reminders" />
      <Tooltip title="Switch on/off the WhatsApp reminders for this LoD" arrow>
        <span>
          <IOSSwitch
            checked={rowData?.current_lod?.is_paused === false}
            onClick={() => {
              handlePauseResume();
            }}
            disabled={isLoading}
          />
        </span>
      </Tooltip>
    </ListItem>
  );
}

// escalade = Escalate + De-escalate
function EscaladeLod({ rowData = null }) {
  const [isLoading, setIsLoading] = useState(false);
  const { lodInvoicesStore, updateOneLodInvoicesStore } = useStoreLod();

  let hasEscalation = null;
  let nextLod = null;

  if (rowData?.current_lod?.type === 'lod_1') {
    hasEscalation = rowData.escalation.includes('lod_2');
    nextLod = 'lod_2';
  }

  if (rowData?.current_lod?.type === 'lod_2') {
    hasEscalation = rowData.escalation.includes('wos');
    nextLod = 'wos';
  }

  const handleUpdate = async () => {
    setIsLoading(true);

    let body = nextLod === 'lod_2' ? ['lod_2'] : ['lod_2', 'wos'];
    if (hasEscalation) {
      body = [];
    }
    const [status, data] = await putUpdateOneInvoiceEscalationAPI(rowData?._id, body);

    setIsLoading(false);

    if (!status) return;

    const updatedInvoice = find(lodInvoicesStore, (inv) => inv?._id.toString() === rowData?._id.toString());

    updatedInvoice.escalation = data[0].escalation;

    updateOneLodInvoicesStore(updatedInvoice);
  };

  const getMessage = () => {
    if (rowData?.current_lod?.is_paused === true) {
      return 'No LoD Escalation as LoD reminder is paused';
    }

    if (hasEscalation) {
      return `Invoice will be automatically escalated to ${nextLod}`;
    }

    return 'No Escalation';
  };

  return hasEscalation != null && (
    <>
      <ListItem>
        <ListItemIcon sx={{ mr: 1.5, minWidth: 0 }}>
          <Escalator />
        </ListItemIcon>
        <ListItemText primary={`Escalate LoD to ${nextLod}`} />
        <Tooltip title="Switch on/off the Escalation of this LoD" arrow>
          <span>
            <IOSSwitch
              checked={hasEscalation}
              onClick={() => {
                handleUpdate();
              }}
              disabled={isLoading}
            />
          </span>
        </Tooltip>

      </ListItem>
      <ListItem>
        <ListItemIcon sx={{ mr: 1.5, minWidth: 0 }}>
          <Box width={24} />
        </ListItemIcon>
        <Typography variant="caption">
          {getMessage()}
        </Typography>

      </ListItem>

    </>
  );
}

function NewListItem(
  {
    disabled = false, onClick = () => { }, icon = <Box />, label = '', tooltip = '', color = '#000',
  },
) {
  return (
    <ListItem disablePadding>
      <Tooltip title={tooltip} arrow>
        <span style={{ width: '100%' }}>
          <ListItemButton
            disabled={disabled}
            onClick={onClick}
          >
            <ListItemIcon sx={{ mr: 1.5, minWidth: 0 }}>
              {icon}
            </ListItemIcon>
            <ListItemText primary={label} sx={{ color }} />
          </ListItemButton>
        </span>
      </Tooltip>
    </ListItem>
  );
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#039855',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#039855',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
