import React from 'react';
import {
  Button, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function NotFoundScreen404() {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>

      <Typography variant="h1" sx={{ color: '#fff', fontWeight: 900 }}>404</Typography>
      <Typography variant="h5" sx={{ color: '#fff', marginTop: '20px' }}>LOST IN Ara?</Typography>
      <Typography variant="h5" sx={{ color: '#fff', marginTop: '20px', textAlign: 'center' }}>Hmm, looks like the page does not exist.</Typography>
      <Button
        variant="outlined"
        color="error"
        sx={{
          height: 50, fontSize: '20px', color: '#fff', borderColor: '#fff',
        }}
        onClick={() => {
          navigate('/admin/user', { replace: true });
        }}
      >
        Back to home
      </Button>

    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#24344c',
    width: '100vw',
    height: '100vh',
    textAlign: 'center',
  },

};
