import { enqueueSnackbar } from 'notistack';

// STORES
import { SecureAdminAxios } from 'util/API';
import { getErrMessageUtil } from 'util/util';

export const createBulkCustomersByCsvAPI = async (body, selectedBizId) => {
  const url = `/user/create-bulk-customer-csv/${selectedBizId}`;

  try {
    const res = await SecureAdminAxios.post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return [true, res.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, err.response.data];
  }
};
