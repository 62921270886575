import React, { useState } from 'react';
import {
  Box, Button,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { GroupAdd, Receipt } from '@mui/icons-material';
import { UserBizHomeCreateBulkCustomerModal, UserBizHomeCreateBulkInvoiceModal } from 'components/User/UserBiz/UserBizHome';

export default function UserBizHomeScreen() {
  const { bizId } = useParams();

  const [isAddCustomer, setIsAddCustomer] = useState(false);
  const [isAddInvoice, setIsAddInvoice] = useState(false);

  return bizId ? (
    <>
      {isAddCustomer && (
        <UserBizHomeCreateBulkCustomerModal
          selectedBizId={bizId}
          handleClose={() => {
            setIsAddCustomer(false);
          }}
        />
      )}
      {isAddInvoice && (
        <UserBizHomeCreateBulkInvoiceModal
          selectedBizId={bizId}
          handleClose={() => {
            setIsAddInvoice(false);
          }}
        />
      )}
      <Box sx={{ px: 4, mt: 5 }} display="flex" flexDirection={'row'}>

        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<GroupAdd />}
          onClick={() => { setIsAddCustomer(true); }}
        >
          Bulk Create Customer
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Receipt />}
          sx={{ ml: 4 }}
          onClick={() => { setIsAddInvoice(true); }}
        >
          Bulk Create Draft Invoice
        </Button>
      </Box>
    </>
  ) : <Box />;
}
