/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import {
  Box, CircularProgress,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { getLodInvoicesAPI } from 'services/lod-api-services';
import { UserBizLodTable } from 'components/User/UserBiz/UserBizLod';
import { useStoreLod } from 'store/lod-store';

const LOD_TYPE = 'lod_1';

export default function UserBizLodOneScreen() {
  const { bizId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const { lodInvoicesStore, setLodInvoicesStore } = useStoreLod();

  const getAvailableLodInvoices = async () => {
    setIsLoading(true);

    const [status, data] = await getLodInvoicesAPI(bizId, LOD_TYPE);

    setIsLoading(false);

    if (!status) return;

    setLodInvoicesStore(data.invoices);
  };

  useEffect(() => {
    if (!bizId) return;

    getAvailableLodInvoices();
  }, [bizId]);

  return bizId && !isLoading ? (
    <Box p={4}>
      <UserBizLodTable invoices={lodInvoicesStore} LOD_TYPE={LOD_TYPE} getAvailableLodInvoices={getAvailableLodInvoices} setIsLoading={setIsLoading} />
    </Box>
  ) : (
    <Box width="100%" textAlign="center" pt={5}>
      <CircularProgress />
    </Box>
  );
}
