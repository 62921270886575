import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { formatAmountUtil } from 'util/util';
import { LoadingButton } from '@mui/lab';
import { postRequestLodAPI } from 'services/lod-api-services';
import { enqueueSnackbar } from 'notistack';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

export default function CustomizedDialogs({
  open, handleClose, selectedInvoices, getAvailableLodInvoices,
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const totalValue = selectedInvoices.reduce((acc, i) => acc + i.processable_value, 0);

  const handleRequestLod = async () => {
    setIsLoading(true);
    const [status, result] = await postRequestLodAPI(selectedInvoices.map((i) => i._id));

    setIsLoading(false);

    if (!status) {
      try {
        enqueueSnackbar('Fail to request LOD, you may screenshot the error message to tech support for more information', { variant: 'error' });
        const prettifiedError = JSON.stringify(result, null, 2);
        setError(prettifiedError);
      } catch (e) {
        console.error(e);
        // Invalid JSON, do nothing
      }

      return;
    }

    enqueueSnackbar('Succesfully sent LOD', { variant: 'success' });
    getAvailableLodInvoices();
    handleClose();
  };

  const onClose = () => {
    if (isLoading) return;

    handleClose();
  };

  return (
    <BootstrapDialog
      fullWidth
      onClose={onClose}
      open={open}
      maxWidth="md"
      hideBackdrop={isLoading}
      disableEscapeKeyDown={isLoading}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        Comfirmation of Request LoD
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid2 container>
          <Grid2 xs={6}>

            <ul style={{ fontSize: 14 }}>
              {selectedInvoices.map((inv) => (
                <li key={inv._id} style={{ marginBottom: 20 }}>
                  {inv.invoice_number}
                  {' '}
                  <ul>
                    <li>
                      MYR
                      {' '}
                      {formatAmountUtil(inv?.processable_value)}
                    </li>
                    <li>{inv._id}</li>
                  </ul>

                </li>
              ))}
            </ul>
          </Grid2>
          {error != null
          && (
          <Grid2 xs={6}>
            <pre style={{ fontSize: 9, color: 'red' }}>{error}</pre>
          </Grid2>
          )}

        </Grid2>
      </DialogContent>
      <DialogActions>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" width="100%" px={2}>
          <Typography whiteSpace="pre-line" variant="caption">
            Total of
            {' '}
            {selectedInvoices.length}
            {' '}
            Invoice(s).
            {'\n'}
            Total Amount of MYR
            {' '}
            {formatAmountUtil(totalValue)}
            .
          </Typography>

          <LoadingButton autoFocus variant="contained" loading={isLoading} onClick={() => handleRequestLod()} disabled={error != null}>
            Request LoD
          </LoadingButton>
        </Box>
      </DialogActions>
    </BootstrapDialog>

  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
