export const getErrMessageUtil = (err = null) => {
  // Here are all the response returned from BE
  if (err?.response?.data?.meta?.errorMessage) {
    return err.response.data.meta.errorMessage;
  }

  if (err?.response?.data?.message) {
    return err.response.data.message;
  }

  if (err?.response?.data?.errors != null && err?.response?.data?.errors.length > 0 && err?.response?.data?.errors?.[0]?.message) {
    return err.response.data.errors[0].message;
  }

  // Here are unexpected reponese and timeout response from axio
  let errMessage = 'Something went wrong, please try again later';

  if (err?.request) {
    errMessage = 'Please check your internet connectivity and try again later';
  } else {
    errMessage = err.message;
  }

  if (errMessage.includes('Timeout')) {
    errMessage = 'Please check your internet connectivity and try again later';
  }

  return errMessage;
};

export function parseJwt(token) {
  if (!token) return null;

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}

export const formatAmountUtil = (amount) => {
  if (amount == null) return '';

  const formattedAmount = amount.toLocaleString(undefined, { minimumFractionDigits: 2 });

  return formattedAmount;
};

export const blockInvalidCharNumberInput = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
