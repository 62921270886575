/* eslint-disable indent */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
// MUI
import { debounceSearchRender } from 'mui-datatables';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  styled,

} from '@mui/material';
// CONST & UTIL
import { getAPI } from 'util/API';
import { useStoreAuth } from 'store/auth-store';
import { format } from 'date-fns';
import { formatAmountUtil, getErrMessageUtil } from 'util/util';
// COMPONENTS
import AraDataTable from 'components/shared/AraDataTable';
import COLORS from 'constants/COLORS';
import { CopyAll, Refresh } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import InvoiceActionButton from 'components/Invoice/InvoiceActionButton';
import { capitalize } from 'lodash';

const INVOICE_STATUSES = ['all', 'outstanding', 'overdue', 'paid', 'voided'];

export default function InvoiceScreen() {
  const [data, setData] = useState([]);

  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState({
    name: 'converted_at',
    direction: 'desc',
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState('overdue');

  const userSessionKeyStore = useStoreAuth((s) => s.userSessionKeyStore);

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    elevation: 0,

    serverSide: true,

    // SORTING
    onColumnSortChange: (changedColumn, direction) => {
      setSort({
        name: changedColumn,
        direction,
      });
    },
    sortOrder: sort,

    // PAGINATION
    page,
    onChangePage: (n) => setPage(n),
    onChangeRowsPerPage: (n) => {
      setPage(0);
      setPageSize(n);
    },
    count: total,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [10, 25, 50],
    jumpToPage: true,
    // SEARCH
    customSearchRender: debounceSearchRender(500),
    searchAlwaysOpen: true,
    searchPlaceholder: 'Search invoices by invoice number',
    searchText: search,
    onSearchChange: (str) => {
      setPage(0);
      if (!str) {
        setSearch('');
      } else {
        setSearch(str);
      }
      return true;
    },
  };

  useEffect(() => {
    handleGetManyInvoices();
  }, [pageSize, page, search, sort, status]);

  const handleGetManyInvoices = () => {
    const API = getAPI('admin');

    const headers = {
      Authorization: `Bearer ${userSessionKeyStore}`,
    };

    const sortStr = JSON.stringify({
      [sort.name]: sort.direction === 'asc' ? 1 : -1,
    });

    const url = `/invoice/many?pageSize=${pageSize}&pageNumber=${page + 1
      }&search=${encodeURIComponent(search)}&sort=${sortStr}&status=${status}`;
    setIsLoading(true);
    API.get(url, { headers })
      .then((res) => {
        setData([...res.data.data.invoices]);
        setTotal(res.data.data.count);
      })
      .catch((err) => {
        const errMessage = getErrMessageUtil(err);
        enqueueSnackbar(errMessage, { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box sx={{ p: 4, pt: 2 }}>
      <Box display="flex" flexDirection="row" alignItems="center" mt={3} mb={5} justifyContent={'space-between'}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Typography variant="h4" sx={{ fontWeight: 700 }}>
            Invoices List
            {' '}
            {total ? `(${total})` : ''}
          </Typography>
          <Stack direction="row" spacing={2} ml={4}>
            {INVOICE_STATUSES.map((s) => (
              <Chip
                sx={{ mr: 1 }}
                label={capitalize(s === 'outstanding' ? 'Not Due' : s)}
                key={s}
                size="small"
                variant={status === s ? 'filled' : 'outlined'}
                color="secondary"
                onClick={() => {
                  setStatus(s);
                }}
              />
            ))}
          </Stack>
        </Box>
        <Button variant="contained" onClick={handleGetManyInvoices} startIcon={<Refresh />}>Refresh List</Button>
      </Box>

      {isLoading ? (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress size={20} color="neutral" />
        </Box>
      ) : (
        <AraDataTable
          title="Invoice List"
          data={data}
          columns={getColumns(data)}
          options={options}
        />
      )}
    </Box>
  );
}

const getColumns = (data) => {
  const col = [
    {
      name: 'invoice_number',
      label: 'Invoice #',
      options: {
        sort: false,
        searchable: true,
        customBodyRenderLite: (i) => {
          const stat = data[i]?.invoice_status;
          const invNo = data[i]?.invoice_number;

          if (!stat) return 'Something went wrong, no stat';

          return (
            <Box>
              <AraStatusChip
                label={stat}
                size="small"
                customColor={getInvoiceBadgeColor(stat).color}
                bgColor={getInvoiceBadgeColor(stat).bgColor}
              />
              <Box sx={{
                display: 'flex', alignItems: 'center', flexDirection: 'row',
              }}
              >
                <Typography variant="body2" sx={{ fontWeight: 500, color: grey[700] }}>
                  {invNo}
                </Typography>
                <IconButton
                  size="small"
                  sx={{ ml: 0.5 }}
                  onClick={() => {
                    navigator.clipboard.writeText(invNo);
                    enqueueSnackbar(`Copied to ${invNo} clipboard`, { variant: 'info', autoHideDuration: 1000 });
                  }}
                >
                  <CopyAll sx={{ fontSize: 14 }} />

                </IconButton>
              </Box>

            </Box>
          );
        },
      },
    },
    {
      name: 'payer_info',
      label: 'Payer/Debtor',
      options: {
        sort: false,
        searchable: true,
        customBodyRenderLite: (i) => {
          const payerInfo = data[i]?.payer_info;

          if (!payerInfo) return 'Something went wrong, no payer';

          return (
            <Box>
              <Typography variant="caption">{payerInfo?.user_type}</Typography>

              <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                {payerInfo.user_type === 'business' ? `${payerInfo?.business_name} \n ${payerInfo.email}`
                  : `${payerInfo?.first_name} ${payerInfo?.last_name} \n ${payerInfo.email}`}
              </Typography>

            </Box>
          );
        },
      },
    },
    {
      name: 'payer_info',
      label: 'Payer Number',
      options: {
        sort: false,
        searchable: true,
        customBodyRenderLite: (i) => {
          const payerInfo = data[i]?.payer_info;

          if (!payerInfo) return 'Something went wrong, no payer';

          return (
            <Box>
              <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                <b>Phone Number:</b>
                {'\n'}
                {payerInfo?.phone_number}
                {'\n\n'}
                <b>SMS Number:</b>
                {'\n'}
                {payerInfo?.office_number || '-'}

              </Typography>

            </Box>
          );
        },
      },
    },
    {
      name: 'converted_at',
      label: 'Converted At',
      options: {
        sort: true,
        customBodyRenderLite: (i) => `${format(new Date(data[i].converted_at || new Date()), 'dd-MMM-yy')}`,
      },
    },
    {
      name: 'processable_value',
      label: 'Value (MYR)',
      options: {
        sort: true,
        searchable: false,
        customBodyRenderLite: (i) => (
          <Typography>
            {formatAmountUtil(data[i]?.processable_value)}
          </Typography>
        ),
      },
    },
    {
      name: 'debt_restructuring_object',
      label: 'Debt Restructuring',
      options: {
        sort: true,
        searchable: false,
        customBodyRenderLite: (i) => {
          const debtRe = data[i]?.debt_restructuring_object;

          if (!debtRe) {
            return <Typography>-</Typography>;
          }

          if (debtRe?.status === 'active') {
            return (
              <AraStatusChip
                label={'Active'}
                size="small"
                customColor={COLORS.green}
                bgColor={COLORS.greenLight}
              />
            );
          }

          return (
            <AraStatusChip
              label={'Draft'}
              size="small"
              customColor={COLORS.gray0}
              bgColor={COLORS.gray99}
            />
          );
        },
      },
    },
    {
      name: 'Action',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const rowData = data[dataIndex];

          return (
            <Box>
              <InvoiceActionButton rowData={rowData} />
            </Box>
          );
        },
      },
    },
  ];
  return col;
};

export const AraStatusChip = styled(
  Chip,
  { shouldForwardProp: (prop) => prop !== 'customColor' && prop !== 'bgColor' },
)(({ theme, customColor, bgColor }) => ({
  padding: theme.spacing(0),
  fontSize: 9,
  color: customColor,
  backgroundColor: bgColor,
  fontWeight: 700,
  height: 18,
  minWidth: 50,
}));

export const getInvoiceBadgeColor = (stat) => {
  let color = COLORS.primary;
  let bgColor = COLORS.primaryLight;
  if (stat === 'outstanding') {
    color = COLORS.primary;
    bgColor = COLORS.primaryLight;
  } else if (stat === 'overdue') {
    color = COLORS.red;
    bgColor = COLORS.redLight;
  } else if (stat === 'paid') {
    color = COLORS.green;
    bgColor = COLORS.greenLight;
  } else if (stat === 'voided') {
    color = COLORS.badgeGrey;
    bgColor = COLORS.badgeGreyLight;
  } else if (stat === 'draft') {
    color = COLORS.badgeGrey;
    bgColor = COLORS.badgeGreyLight;
  }

  return { color, bgColor };
};
