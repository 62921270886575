import React from 'react';
import { SvgIcon } from '@mui/material';

/* eslint-disable max-len */
export function CustomLodIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 17">
      <path d="M9.00002 0.5C7.94169 0.5 7.00002 1.16667 6.65002 2.16667H1.50002V3.83333H3.12502L0.666691 9.66667C0.275025 11.3333 1.50002 12.1667 3.58336 12.1667C5.66669 12.1667 6.96669 11.3333 6.50002 9.66667L4.04169 3.83333H6.64169C6.91669 4.54167 7.45836 5.08333 8.16669 5.35833V14.6667H0.666691V16.3333H17.3334V14.6667H9.83336V5.35C10.5417 5.08333 11.0834 4.54167 11.35 3.83333H13.9584L11.5 9.66667C11.1084 11.3333 12.3334 12.1667 14.4167 12.1667C16.5 12.1667 17.8 11.3333 17.3334 9.66667L14.875 3.83333H16.5V2.16667H11.3584C11 1.16667 10.0584 0.5 9.00002 0.5ZM9.00002 2.16667C9.22104 2.16667 9.433 2.25446 9.58928 2.41074C9.74556 2.56702 9.83336 2.77899 9.83336 3C9.83336 3.22101 9.74556 3.43298 9.58928 3.58926C9.433 3.74554 9.22104 3.83333 9.00002 3.83333C8.77901 3.83333 8.56705 3.74554 8.41077 3.58926C8.25449 3.43298 8.16669 3.22101 8.16669 3C8.16669 2.77899 8.25449 2.56702 8.41077 2.41074C8.56705 2.25446 8.77901 2.16667 9.00002 2.16667ZM3.58336 6.54167L4.83336 9.66667H2.33336L3.58336 6.54167ZM14.4167 6.54167L15.6667 9.66667H13.1667L14.4167 6.54167Z" />
    </SvgIcon>
  );
}
