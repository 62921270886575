import React from 'react';
import {
  Box, styled, Toolbar,
} from '@mui/material';

import { Navigate, Outlet } from 'react-router-dom';
import LAYOUT from 'constants/LAYOUT';
import { useStoreAuth } from 'store/auth-store';
import AppBarHeader from './AdminLayoutHeader';

const { APP_BAR_HEIGHT } = LAYOUT;

export default function AdminLayout({ screen, ...props }) {
  const isAuthStore = useStoreAuth((s) => s.isAuthStore);
  const userSessionKeyStore = useStoreAuth((s) => s.userSessionKeyStore);

  return isAuthStore && userSessionKeyStore ? (
    <Box sx={{ display: 'flex' }}>
      <AppBarHeader props={props} />

      <Main>
        <Toolbar sx={{ height: APP_BAR_HEIGHT }} disableGutters />
        <Outlet />
      </Main>
    </Box>
  ) : (
    <Navigate to="/" replace />
  );
}

const Main = styled(
  'main',
)(({ theme }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

}));
