import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { formatAmountUtil } from 'util/util';
import { Download } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';

export default function ActiveMarkPaidTimeline({ markAsPaidArr }) {
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}
    >

      {markAsPaidArr.map((m) => (
        <TimelineItem key={m.marked_as_paid_at}>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <Typography>
              {format(new Date(m.marked_as_paid_at), 'dd MMM yyyy')}
            </Typography>
            <Typography variant="caption">
              {format(new Date(m.marked_as_paid_at), 'p (zzz)')}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              {m.attachments != null ? (
                <IconButton
                  color="primary"
                  onClick={() => {
                    window.open(m?.attachments, '_blank', 'noreferrer');
                  }}
                  size="small"
                >
                  <Download />
                </IconButton>
              ) : <Box />}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="body1" fontWeight={'bold'}>
              {`MYR ${formatAmountUtil(m.amount_paid)}`}
            </Typography>
            <Typography variant="caption">
              {m.payment_mode}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}

    </Timeline>
  );
}
