/* eslint-disable indent */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
// MUI
import { LoginRounded, Refresh, RemoveRedEye } from '@mui/icons-material';
import { debounceSearchRender } from 'mui-datatables';
import {
  Box,
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Grid,
  Divider,
  Stack,
  Chip,
  Button,
} from '@mui/material';
// CONST & UTIL
import { getAPI } from 'util/API';
import { useStoreAuth } from 'store/auth-store';
import { format } from 'date-fns';
import { getErrMessageUtil } from 'util/util';
// COMPONENTS
import { find } from 'lodash';
import ContentGrid from 'components/shared/ContentGrid/ContentGrid';
import CONFIG from 'constants/CONFIG';
import AraDataTable from 'components/shared/AraDataTable';

export default function UserScreen() {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);

  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState({
    name: 'created_at',
    direction: 'desc',
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [userListType, setUserListType] = useState('verified');

  const userSessionKeyStore = useStoreAuth((s) => s.userSessionKeyStore);

  const columns = [
    {
      name: 'first_name',
      label: 'Name',
      options: {
        sort: false,
        customBodyRenderLite: (i) => {
          const verifiedBiz = find(data[i]?.businesses, (biz) => biz?.account_type === 'verified') || null;

          return (
            <Box>
              {verifiedBiz != null
                && <Typography fontWeight="bold">{verifiedBiz.name}</Typography>}
              <Typography variant="body2">{`${data[i].first_name} ${data[i].last_name}`}</Typography>
            </Box>
          );
        },
        // customBodyRenderLite: (i) => `${data[i].first_name} ${data[i].last_name}`,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        sort: false,
      },
    },
    {
      name: 'created_at',
      label: 'Created',
      options: {
        sort: true,
        customBodyRenderLite: (i) => `${format(new Date(data[i].created_at || new Date()), 'dd-MMM-yy')}`,
      },
    },
    {
      name: 'last_accessed_at',
      label: 'Last Access',
      options: {
        sort: true,
        customBodyRenderLite: (i) => (data?.[i]?.last_accessed_at ? `${format(
          new Date(data[i].last_accessed_at),
          'dd-MMM-yy, p',
        )}` : 'N/A'),
      },
    },
    {
      name: 'Action',
      options: {
        sort: false,
        empty: true,
        // eslint-disable-next-line
        customBodyRenderLite: (i) => (
          <Box>
            {find(data[i]?.businesses, (biz) => biz.account_type === 'verified') && (
              <Tooltip title="View Verified Business" arrow>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    window.open(`/admin/user/${data[i]._id}`, '_blank');
                  }}
                >
                  <RemoveRedEye fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Login as User" arrow>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleLoginAsUser(data[i])}
              >
                <LoginRounded fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    },
  ];

  const handleLoginAsUser = async (user) => {
    const API = getAPI('admin');
    const headers = {
      Authorization: `Bearer ${userSessionKeyStore}`,
    };
    const url = `/auth/get/user-magic-link/${user.email}`;
    API.get(url, { headers })
      .then((res) => {
        window.open(res.data.data.magicLink, '_blank');
      })
      .catch((err) => {
        const errMessage = getErrMessageUtil(err);
        enqueueSnackbar(errMessage, { variant: 'error' });
      });
  };

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    elevation: 0,

    serverSide: true,

    // SORTING
    onColumnSortChange: (changedColumn, direction) => {
      setSort({
        name: changedColumn,
        direction,
      });
    },
    sortOrder: sort,

    // PAGINATION
    page,
    onChangePage: (n) => setPage(n),
    onChangeRowsPerPage: (n) => {
      setPage(0);
      setPageSize(n);
    },
    count: total,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [10, 25, 50],
    jumpToPage: true,
    // SEARCH
    customSearchRender: debounceSearchRender(500),
    searchAlwaysOpen: true,
    searchPlaceholder: 'Search users by email',
    searchText: search,
    onSearchChange: (str) => {
      setPage(0);
      if (!str) {
        setSearch('');
      } else {
        setSearch(str);
      }
      return true;
    },

    // EXPANDABLE
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    isRowExpandable: (i) => {
      const user = data[i];

      const verifiedBiz = find(user?.businesses || [], (biz) => biz?.account_type === 'verified');

      if (verifiedBiz) {
        return true;
      }
      return false;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const businesses = data[rowMeta.rowIndex]?.businesses;

      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Box sx={{ px: 3, py: 2 }}>

              {businesses.map((biz) => (biz?.account_type === 'verified' ? (

                <Grid container direction="row" spacing={2} key={biz?._id}>
                  <ContentGrid label="Account Type" value={biz?.account_type} />
                  <ContentGrid label="Business Name" value={biz?.name} />
                  <ContentGrid
                    label="Phone Number"
                    value={biz?.phone_number || '-'}
                  />
                  <ContentGrid label="SMS Number" value={biz?.office_number || '-'} />
                  <ContentGrid
                    label="Business Short Name"
                    value={biz?.short_name}
                  />
                  <ContentGrid label="Business Type" value={biz?.type} />
                  <Grid item xs={12}>
                    <Typography>Payex Onboarding Status</Typography>
                    <Typography variant="h6">
                      {biz?.payex?.onboarding_status}
                    </Typography>

                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Stripe Connect</Typography>
                    <Typography variant="h6">
                      {biz?.stripe?.onboarding_status}
                    </Typography>
                    {biz?.stripe?.stripe_connect_account_id
                      && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${CONFIG.STRIPE_CONNECT_URL[process.env.REACT_APP_ENV]
                            }/${biz?.stripe?.stripe_connect_account_id}`}
                        >
                          {`${CONFIG.STRIPE_CONNECT_URL[process.env.REACT_APP_ENV]}/${biz?.stripe?.stripe_connect_account_id
                            }`}
                        </a>
                      )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 4 }} />
                  </Grid>
                </Grid>
              ) : <Box key={biz?._id} />))}

            </Box>

          </TableCell>
        </TableRow>
      );
    },
  };

  useEffect(() => {
    handleGetUserList();
  }, [pageSize, page, search, sort, userListType]);

  const handleGetUserList = () => {
    const API = getAPI('admin');

    const headers = {
      Authorization: `Bearer ${userSessionKeyStore}`,
    };

    const sortStr = JSON.stringify({
      [sort.name]: sort.direction === 'asc' ? 1 : -1,
    });

    const url = `/user/get/many?pageSize=${pageSize}&pageNumber=${page + 1
      }&search=${encodeURIComponent(search)}&sort=${sortStr}&userType=${userListType}`;
    setIsLoading(true);
    API.get(url, { headers })
      .then((res) => {
        setData([...res.data.data.users]);
        setTotal(res.data.data.count);
      })
      .catch((err) => {
        const errMessage = getErrMessageUtil(err);
        enqueueSnackbar(errMessage, { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box sx={{ p: 4, pt: 2 }}>
      <Box display="flex" flexDirection="row" alignItems="center" mt={3} mb={5} justifyContent={'space-between'}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Typography variant="h4" sx={{ fontWeight: 700 }}>
            Users List
            {' '}
            {total ? `(${total})` : ''}
          </Typography>
          <Stack direction="row" spacing={1} ml={4}>
            <Chip
              sx={{ mr: 1 }}
              label="All"
              size="small"
              variant={userListType === 'all' ? 'filled' : 'outlined'}
              color="secondary"
              onClick={() => {
                setUserListType('all');
              }}
            />
            <Chip
              sx={{ mr: 1 }}
              size="small"
              label="Verified Only"
              variant={userListType === 'verified' ? 'filled' : 'outlined'}
              color="secondary"
              onClick={() => {
                setUserListType('verified');
              }}
            />
          </Stack>
        </Box>
        <Button variant="contained" onClick={handleGetUserList} startIcon={<Refresh />}>Refresh List</Button>
      </Box>

      {isLoading ? (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress size={20} color="neutral" />
        </Box>
      ) : (
        <AraDataTable
          title="Users List"
          data={data}
          columns={columns}
          options={options}
        />
      )}
    </Box>
  );
}
