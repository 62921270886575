import {
  Grid, styled, Typography,
} from '@mui/material';
import COLORS from 'constants/COLORS';
import LAYOUT from 'constants/LAYOUT';
import { upperFirst, last } from 'lodash';
import React from 'react';

export default function ComingSoonScreen() {
  const path = last(window.location.pathname.split('/'));

  return (
    <FullScreenContainer
      container
      maxWidth="false"
      justifyContent="center"
      alignItems="center"
      spacing={10}
      sx={{ p: 10 }}
    >

      <Grid item xs={12} md={6}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          {upperFirst(path)}
          {' '}
          coming soon
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
        <img alt="in progress" src="/images/in-progress.png" width="80%" style={{ maxHeight: '60vh' }} />
      </Grid>

    </FullScreenContainer>
  );
}

const FullScreenContainer = styled(Grid)(() => ({
  height: `calc(100vh - ${LAYOUT.APP_BAR_HEIGHT}px)`,
  backgroundColor: COLORS.secondaryBG,
}));
