const CONFIG = {
  API: {
    local: {
      V1_ADMIN_API_URL: 'http://127.0.0.1:5000/api/v1',
      V1_INVOICE_API_URL: 'https://api.ara-pay.com/development/ara/invoice/api/v1',
      V1_FILE_API_URL: 'https://api.ara-pay.com/development/ara/file/api/v1',
    },
    development: {
      V1_ADMIN_API_URL: 'https://api.ara-pay.com/development/ara/admin/api/v1',
      V1_INVOICE_API_URL: 'https://api.ara-pay.com/development/ara/invoice/api/v1',
      V1_FILE_API_URL: 'https://api.ara-pay.com/development/ara/file/api/v1',
    },
    staging: {
      V1_ADMIN_API_URL: 'https://api.ara-pay.com/staging/ara/admin/api/v1',
      V1_INVOICE_API_URL: 'https://api.ara-pay.com/staging/ara/invoice/api/v1',
      V1_FILE_API_URL: 'https://api.ara-pay.com/staging/ara/file/api/v1',
    },
    production: {
      V1_ADMIN_API_URL: 'https://v1.ara-app.com/ara/admin/api/v1',
      V1_INVOICE_API_URL: 'https://v1.ara-app.com/ara/invoice/api/v1',
      V1_FILE_API_URL: 'https://v1.ara-app.com/ara/file/api/v1',
    },
  },
  STRIPE_PK: {
    local:
      'pk_test_51KbM7sIS0GMc8nMTCFQkUFK5QSVqXT9eRH25e0AJibUyIwzHNSEVrHnuBu8sJDtW6qEfLGzbAAGX1wCYiLl36QlO00wecSKwxt',
    development:
      'pk_test_51KbM7sIS0GMc8nMTCFQkUFK5QSVqXT9eRH25e0AJibUyIwzHNSEVrHnuBu8sJDtW6qEfLGzbAAGX1wCYiLl36QlO00wecSKwxt',
    staging:
      'pk_test_51KbM7sIS0GMc8nMTCFQkUFK5QSVqXT9eRH25e0AJibUyIwzHNSEVrHnuBu8sJDtW6qEfLGzbAAGX1wCYiLl36QlO00wecSKwxt',
    production:
      'pk_live_51KbM7sIS0GMc8nMThZze1zm9CgMQYEfA8vaxqWdq6IDHeI7BGhT38AbvUj5qH1FqwWBdHnyEKoXnu9z0bGiMrhOl00u6gInZIY',
  },
  STRIPE_CONNECT_URL: {
    local: 'https://dashboard.stripe.com/test/connect/accounts',
    development: 'https://dashboard.stripe.com/test/connect/accounts',
    staging: 'https://dashboard.stripe.com/test/connect/accounts',
    production: 'https://dashboard.stripe.com/connect/accounts',
  },
};

export default CONFIG;
