import { alpha } from '@mui/material';

const SHADOWS = {
  // Primary Color
  primary: '0 2px 20px 0 rgb(0 0 0 / 10%)',
  secondary: '4px 2px 15px 0 rgb(0 0 0 / 10%)',
  z1: `0 1px 2px 0 ${alpha('#919EAB', 0.16)}`,
  z8: `0 8px 16px 0 ${alpha('#919EAB', 0.16)}`,
  z12: `0 12px 24px -4px ${alpha('#919EAB', 0.16)}`,
  z16: `0 16px 32px -4px ${alpha('#919EAB', 0.16)}`,
  z20: `0 20px 40px -4px ${alpha('#919EAB', 0.16)}`,
  z24: `0 24px 48px 0 ${alpha('#919EAB', 0.16)}`,

  listBox: '0 .5rem 1rem rgba(0,0,0,.15)',
};

export default SHADOWS;
