import React from 'react';
import './style.css';

/* eslint-disable react/jsx-no-useless-fragment */
export default function FullScreenLoader({ isLoading = false }) {
  return (
    <>
      {isLoading && (
        <div className="wrapper">
          <div className="loading">LOADING</div>
          <div className="boxes">
            <div className="box">
              <div />
              <div />
              <div />
            </div>
            <div className="box">
              <div />
              <div />
              <div />
            </div>
            <div className="box">
              <div />
              <div />
              <div />
            </div>
            <div className="box">
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
